import React from 'react';
import Container from '@mui/material/Container';

import logo from 'assets/svg/logoWithText.svg';
import * as styles from 'components/AppBar/styles';
import AuthLink from 'components/AuthLink';
import AuthTitle from 'components/AuthTitle';
import { REGISTRATION_PAGE } from 'constants/locations';
import { authFormContainer, gridContainer } from 'styles/container';

import Form from './components/Form';

function ResetPassword() {
  return (
    <Container style={authFormContainer}>
      <div style={{ marginBottom: '1rem' }}>
        <img src={logo} alt="" style={styles.svg} />
      </div>

      <AuthTitle
        isBack
        title="resetPasswordTitle"
        subTitle="resetPasswordSubTitle"
      />
      <div style={gridContainer}>
        <Form />
        <AuthLink
          link={REGISTRATION_PAGE}
          question="dontHaveAccount"
          text="registration"
        />
      </div>
    </Container>
  );
}

export default ResetPassword;
