import { color } from '../../styles/text';

export const container = {
  marginBottom: '40px',
};

export const title = {
  fontWeight: 700,
  fontSize: '46px',
};

export const subTitle = {
  fontWeight: 500,
  fontSize: '16px',
  color: color.lightGray,
};

export const btnBack = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  width: '100px',
};
