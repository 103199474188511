export const errorsConfig = {
  'auth/wrong-password': {
    message: 'password',
    field: 'password',
  },
  'auth/user-not-found': {
    message: 'emailUsed',
    field: 'email',
  },
};
