import { createSlice } from '@reduxjs/toolkit';

import { API_STATUS } from 'constants/common';

import { getProfile, updateOrganization } from './actions';
import { mapperToProfile } from './mappers';

const initialState = {
  account: {},
  organization: {
    socials: {},
    eventCounter: 0,
  },
  id: null,
  status: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state) => ({
      ...state,
      status: API_STATUS.loading,
    }));
    builder.addCase(getProfile.fulfilled, (state, { payload }) => ({
      status: API_STATUS.success,
      ...mapperToProfile(payload.data),
    }));
    builder.addCase(getProfile.rejected, (state) => ({
      ...state,
      status: API_STATUS.error,
    }));
    builder.addCase(updateOrganization.fulfilled, (state, action) => ({
      ...state,
      organization: action.payload.data,
    }));
  },
});

export default profileSlice.reducer;
