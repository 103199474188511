import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import * as yup from 'yup';

import MyForm from 'components/Form';
import MaskTextFieldForm from 'components/formFields/MaskTextFieldForm';
import SelectFieldForm from 'components/formFields/SelectFieldForm';
import TextFieldForm from 'components/formFields/TextFieldForm';
import FormSection from 'components/FormSection';
import SettingHeader from 'components/SettingHeader';
import { city, MAX_FILE_SIZE } from 'constants/common';
import { generateUUID, getUrl } from 'helpers';
import { deleteFile, getLink, uploadFile } from 'services/firebase/storage';
import { updateOrganization } from 'store/profile/actions';
import { getOrganization } from 'store/profile/selectors';
import Button from 'UIComponent/Button';

import * as styles from './styles';
import { organizationSchema } from './validations';

function SettingOrganization() {
  const submitRef = useRef();
  const fileRef = useRef();
  const imageRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const organization = useSelector(getOrganization);
  const [logoUrl, setLogoUrl] = useState('');

  useEffect(() => {
    const url = getUrl({ type: 'organizations', fileName: organization.logo, id: organization.id });
    getLink(url, setLogoUrl);
  }, [organization.logo]);

  const {
    handleSubmit,
    control,
    formState: { errors = {} },
  } = useForm({
    resolver: yupResolver(yup.object(organizationSchema)),
    defaultValues: {
      name: organization.name,
      description: organization.description,
      country: organization.country,
      city: organization.city,
      place: organization.place,
      street: organization.street,
      phone: organization.phone,
      instagram: organization.socials?.instagram || '',
      telegram: organization.socials?.telegram || '',
    },
  });

  const onSubmit = (data) => {
    const { instagram, telegram } = data;
    dispatch(updateOrganization({
      id: organization.id,
      ...data,
      socials: {
        instagram,
        telegram,
      },
    })).then(() => {
      toast.success(t('notification.organizationUpdated'));
    });
  };

  const setLogo = (logo) => {
    setLogoUrl(logo);
    onSubmit({ logo });
    if (imageRef.current !== logo) {
      const url = getUrl({ type: 'organizations', fileName: imageRef.current, id: organization.id });

      deleteFile(url);
      imageRef.current = logo;
    }
  };

  const changeHandler = (event) => {
    const file = event.target.files[0];
    if (file.size > MAX_FILE_SIZE) {
      toast.warn(t('notification.largeFile'));
      return;
    }

    const fileType = file.name.split('.').at(-1);
    const fileName = `${generateUUID()}.${fileType}`;
    const filePath = 'organizations';
    if (organization.logo !== fileName) {
      imageRef.current = organization.logo;
    }

    uploadFile({
      file, filePath, fileName, callback: setLogo,
    });
  };

  return (
    <Container>
      <SettingHeader
        submitRef={submitRef}
        currentPage="settingOrganization"
        title="settingOrganization"
        showButton
      />

      <input type="file" onChange={changeHandler} hidden ref={fileRef} accept=".jpg, .jpeg, .png" />

      <MyForm onSubmit={handleSubmit(onSubmit)} styles={styles.formBuilderContainer}>
        <FormSection
          title="mainInfo"
          styles={styles.mainInfoContainer}
        >
          <div style={styles.avatarContainer}>
            <div style={styles.avatarImgContainer} onClick={() => fileRef.current?.click()}>
              <Avatar sx={styles.avatar} src={logoUrl} variant="rounded" alt="..." />
              <div style={styles.addPhoto}><PhotoCameraIcon color="primary" /></div>
            </div>

            <div style={styles.avatarText}>{t('organizationSetting.logoDescription')}</div>
          </div>

          <TextFieldForm
            required
            id="name"
            error={errors.name}
            control={control}
          />

          <TextFieldForm
            id="description"
            error={errors.description}
            control={control}
            multiline
          />
        </FormSection>

        <FormSection
          title="address"
          styles={styles.mainInfoContainer}
        >
          <SelectFieldForm
            id="city"
            options={city}
            error={errors.city}
            control={control}
          />
          <TextFieldForm
            id="place"
            error={errors.place}
            control={control}
          />
          <TextFieldForm
            id="street"
            error={errors.street}
            control={control}
          />
        </FormSection>

        <FormSection
          title="contacts"
          styles={styles.mainInfoContainer}
        >

          <MaskTextFieldForm
            id="phone"
            error={errors.phone}
            control={control}
            mask="+389 99 999 99 99"
          />

          <TextFieldForm
            id="telegram"
            error={errors.telegram}
            control={control}
          />

          <TextFieldForm
            id="instagram"
            error={errors.instagram}
            control={control}
          />

        </FormSection>

        <Button
          ref={submitRef}
          fullWidth
          type="submit"
          hidden
        />

      </MyForm>
    </Container>
  );
}

export default SettingOrganization;
