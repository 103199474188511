import { FILTER_EVENTS_STATUS } from 'constants/common';

export const filterDefaultValue = FILTER_EVENTS_STATUS.FUTURE;

export const filterEventFilterConfig = [
  { value: FILTER_EVENTS_STATUS.ALL, label: 'all' },
  { value: FILTER_EVENTS_STATUS.FUTURE, label: 'future' },
  { value: FILTER_EVENTS_STATUS.ON_REVIEW, label: 'onReview' },
  { value: FILTER_EVENTS_STATUS.PUBLISH, label: 'published' },
  { value: FILTER_EVENTS_STATUS.DRAFT, label: 'draft' },
  { value: FILTER_EVENTS_STATUS.REJECT, label: 'reject' },
  { value: FILTER_EVENTS_STATUS.PAST, label: 'past' },
];
