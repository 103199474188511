import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { object, string } from 'prop-types';

import Checkbox from 'components/Checkbox';

function CheckboxForm(props) {
  const {
    id,
    control,
  } = props;
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={id}
      render={({ field }) =>
        (
          <Checkbox
            title={t(`auth.${id}`)}
            onChange={field.onChange}
            name={field.name}
            value={field.value}
          />
        )}
    />
  );
}

CheckboxForm.propTypes = {
  id: string.isRequired,
  control: object.isRequired,
};

export default CheckboxForm;
