import React from 'react';
import { node } from 'prop-types';

import { AuthContainer, ChildrenContainer, Container } from './styles';

function AuthLayout(props) {
  const { children } = props;

  return (
    <AuthContainer>
      <Container>
        <ChildrenContainer>
          {children}
        </ChildrenContainer>
      </Container>
    </AuthContainer>
  );
}

AuthLayout.propTypes = {
  children: node.isRequired,
};

export default AuthLayout;
