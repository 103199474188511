import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { string } from 'prop-types';

import { DASHBOARD_PAGE } from 'constants/locations';

function SuperAdmin({ redirectPath = DASHBOARD_PAGE }) {
  const isAdmin = useSelector((state) => state.profile?.account?.admin);

  return isAdmin ? <Outlet /> : <Navigate replace to={redirectPath} />;
}

SuperAdmin.propTypes = {
  redirectPath: string,
};

SuperAdmin.defaultProps = {
  redirectPath: '',
};

export default SuperAdmin;
