import MuiListItemIcon from '@mui/material/ListItemIcon';
import { styled } from '@mui/material/styles';

const DrawerListItemIcon = styled(MuiListItemIcon)(({ theme, open: isOpen, active }) =>
  ({
    minWidth: 0,
    justifyContent: 'center',
    marginRight: isOpen ? theme.spacing(3) : 0,
    color: active && '#0084FF',
    transition: theme.transitions.create('margin-right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }));

export default DrawerListItemIcon;
