export const containerLayout = {
  display: 'flex',
};

export const mainContent = {
  position: 'relative',
  backgroundColor: '#ffffff',
  minHeight: '100vh',
  flexGrow: 1,
  p: 3,
};
