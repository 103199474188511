import React from 'react';
import { useTranslation } from 'react-i18next';

import GoogleIcon from 'assets/svg/google.svg';
import { signInWithSocial } from 'services/firebase/auth';
import Button from 'UIComponent/Button';

import * as styles from './styles';

function LoginWithGoogle() {
  const { t } = useTranslation();
  return (
    <Button
      onClick={() => signInWithSocial('google')}
      variant="contained"
      style={styles.container}
      fullWidth
    >
      <img src={GoogleIcon} alt="enterWithGoogle" style={styles.svg} />
      { t('socialMedia.enterWithGoogle') }
    </Button>
  );
}

export default LoginWithGoogle;
