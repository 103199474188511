import React from 'react';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@mui/icons-material/Home';
import { array, bool, string } from 'prop-types';

import { DASHBOARD_PAGE } from 'constants/locations';
import MyBreadcrumbs from 'UIComponent/Breadcrumbs';
import Link from 'UIComponent/Link';

import * as styles from './styles';

function Breadcrumbs(props) {
  const { t } = useTranslation();
  const { breadcrumbs, current, useTranslate } = props;
  return (
    <div role="presentation">
      <MyBreadcrumbs aria-label="breadcrumb">
        <Link to={DASHBOARD_PAGE}>
          <div style={styles.dashboardContainer}>
            <HomeIcon fontSize="small" />
            <div>{t('breadcrumb.dashboard')}</div>
          </div>
        </Link>
        {breadcrumbs.map((item) => (
          <Link to={item.link} key={item.link}>
            <div style={styles.dashboardContainer}>
              <div>{ t(`breadcrumb.${item.title}`)}</div>
            </div>
          </Link>
        ))}
        <div style={styles.currentPage}>
          {useTranslate ? t(`breadcrumb.${current}`) : current}
        </div>
      </MyBreadcrumbs>
    </div>
  );
}

Breadcrumbs.propTypes = {
  breadcrumbs: array,
  current: string.isRequired,
  useTranslate: bool,
};

Breadcrumbs.defaultProps = {
  breadcrumbs: [],
  useTranslate: true,
};

export default Breadcrumbs;
