import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import MyForm from 'components/Form';
import TextFieldForm from 'components/formFields/TextFieldForm';
import { resetPassword } from 'services/firebase/auth';
import Button from 'UIComponent/Button';

import { resetPasswordSchema } from '../validations';

function Form() {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors = {} },
  } = useForm({
    resolver: yupResolver(yup.object(resetPasswordSchema)),
  });

  const onSubmit = (data) => {
    const { email } = data;
    resetPassword(email)
      .finally(() => {
        toast.success(t('sendToEmail'));
      });
  };

  return (
    <MyForm onSubmit={handleSubmit(onSubmit)}>
      <TextFieldForm
        id="email"
        error={errors.email}
        control={control}
      />

      <Button
        fullWidth
        variant="contained"
        type="submit"
      >
        { t('btn.reset') }
      </Button>
    </MyForm>
  );
}

export default Form;
