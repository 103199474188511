import {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword, signInWithPopup,
  signOut,
} from 'firebase/auth';

import { auth } from './index';

const googleProvider = new GoogleAuthProvider();

const providers = {
  google: googleProvider,
};

const login = ({ email, password, remember: isRemember }) =>
  setPersistence(auth, isRemember ? browserLocalPersistence : browserSessionPersistence)
    .then((() => signInWithEmailAndPassword(auth, email, password)));

const logout = () => signOut(auth);

const resetPassword = (email) =>
  sendPasswordResetEmail(auth, email);

const createUser = ({ email, password }) =>
  createUserWithEmailAndPassword(auth, email, password);

export const signInWithSocial = (provider) =>
  signInWithPopup(auth, providers[provider]);

export {
  login,
  logout,
  createUser,
  resetPassword,
};
