import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { resetEvents, setEventsFilter } from 'store/events';
import { getEventsFilter } from 'store/events/selectors';

import { filterDefaultValue, filterEventFilterConfig } from '../configs';
import * as styles from '../styles';

function EventFilter() {
  const filter = useSelector(getEventsFilter);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setEventsFilter(filterDefaultValue));
    return () => {
      dispatch(resetEvents());
    };
  }, []);

  const handlerStatus = (e) => {
    const { target: { value } } = e;
    dispatch(setEventsFilter(value));
  };

  if (!filterDefaultValue) return null;

  return (
    <FormControl>
      <InputLabel id="events-filter">{t('filter.eventFilter')}</InputLabel>
      <Select
        labelId="events-filter"
        label={t('filter.eventFilter')}
        value={filter || filterDefaultValue}
        onChange={handlerStatus}
        style={styles.filter}
      >
        {filterEventFilterConfig.map(({ value, label }) =>
          (
            <MenuItem key={value} value={value}>
              {t(`filter.${label}`)}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default EventFilter;
