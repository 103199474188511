import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { func, object } from 'prop-types';
import * as yup from 'yup';

import Divider from 'components/Divider';
import MyForm from 'components/Form';
import MaskTextFieldForm from 'components/formFields/MaskTextFieldForm';
import SelectFieldForm from 'components/formFields/SelectFieldForm';
import TextFieldForm from 'components/formFields/TextFieldForm';
import FormSection from 'components/FormSection';
import {
  city,
  eventStatus,
  eventType,
  MAX_FILE_SIZE,
  OUR_ORGANIZATIONS,
  priorities,
  tags,
} from 'constants/common';
import { generateUUID, getUrl } from 'helpers';
import { deleteFile, getLink, uploadFile } from 'services/firebase/storage';
import { getIsAdmin } from 'store/profile/selectors';
import Button from 'UIComponent/Button';

import EventImage from './components/EventImage';
import styles from './styles';
import { eventSchema } from './validations';

function Event(props) {
  const {
    onSubmit,
    submitRef,
    values,
    removeEvent,
    savePhoto,
    organizationId,
    sendToTelegram,
  } = props;
  const { t } = useTranslation();
  const isAdmin = useSelector(getIsAdmin);
  const fileRef = useRef();
  const imageRef = useRef(values?.logo);
  const [logoUrl, setLogoUrl] = useState(values?.logo || '');
  const isOrganizationEvent = !OUR_ORGANIZATIONS.includes(organizationId);

  const {
    handleSubmit,
    control,
    // watch,
    setValue,
    formState: { errors = {} },
  } = useForm({
    defaultValues: values,
    resolver: yupResolver(yup.object(eventSchema)),
  });

  // const watchShowCost = watch('price');

  useEffect(() => {
    if (values?.logo) {
      const url = getUrl({ type: 'events', fileName: values?.logo, id: values.id });
      if (values?.logo?.length < 45) {
        getLink(url, setLogoUrl);
      } else {
        setLogoUrl(values.logo);
      }
      setValue('logo', values?.logo);
    }
  }, [values?.logo]);

  const setLogo = (logo) => {
    savePhoto({ logo });
    if (imageRef.current && imageRef.current !== logo) {
      const url = getUrl({ type: 'events', fileName: imageRef.current, id: values.id });
      deleteFile(url);
      imageRef.current = logo;
    }
    setLogoUrl(logo);
  };

  const changeHandler = (event) => {
    const file = event.target.files[0];
    if (file.size > MAX_FILE_SIZE) {
      toast.warn(t('notification.largeFile'));
      return;
    }

    const fileType = file.name.split('.').at(-1);
    const fileName = `${generateUUID()}.${fileType}`;
    if (values.logo !== fileName) {
      imageRef.current = values.logo;
    }

    uploadFile({
      file,
      filePath: 'events',
      fileName,
      callback: setLogo,
    });
  };

  return (
    <div style={styles.container}>
      <input type="file" onChange={changeHandler} hidden ref={fileRef} accept=".jpg, .jpeg, .png" />

      <MyForm onSubmit={handleSubmit(onSubmit)}>

        {values.id && (
        <FormSection title="eventPhoto">
          <EventImage fileRef={fileRef} url={logoUrl} />
        </FormSection>
        )}

        <FormSection title="generalInfo">
          {isOrganizationEvent && <div style={styles.organizationLabel}>{t('organizationEvent')}</div>}

          <SelectFieldForm
            required
            id="status"
            options={eventStatus}
            error={errors.status}
            control={control}
          />

          <Button
            onClick={sendToTelegram}
            variant="contained"
            style={styles.btn}
          >
            {t('sendToTelegram')}
          </Button>
        </FormSection>

        <FormSection title="mainInfo">
          <TextFieldForm
            required
            id="name"
            error={errors.name}
            control={control}
          />

          <TextFieldForm
            required
            id="description"
            error={errors.description}
            control={control}
            multiline
          />

          <SelectFieldForm
            required
            id="type"
            options={eventType}
            error={errors.type}
            control={control}
          />
        </FormSection>
        <FormSection title="address">
          <SelectFieldForm
            id="city"
            options={city}
            error={errors.city}
            control={control}
          />
          <TextFieldForm
            id="place"
            error={errors.place}
            control={control}
          />
          <TextFieldForm
            id="street"
            error={errors.street}
            control={control}
          />
        </FormSection>

        <FormSection title="tickets">
          {values?.prices?.length === 0 && <h5>Free event</h5>}
          {values?.prices?.map((item, index) => (
            <div style={styles.ticketSection} key={item.id}>
              <TextFieldForm
                title="ticketName"
                disabled
                id={`prices[${index}].title`}
                control={control}
              />
              <TextFieldForm
                title="price"
                disabled
                id={`prices[${index}].cost`}
                control={control}
              />
            </div>
          ))}
        </FormSection>

        <FormSection title="contacts">
          <MaskTextFieldForm
            id="phone"
            error={errors.name}
            control={control}
            mask="+389 99 999 99 99"
          />

          <TextFieldForm
            id="instagram"
            error={errors.description}
            control={control}
          />

          <TextFieldForm
            id="telegram"
            error={errors.description}
            control={control}
          />

          <TextFieldForm
            id="website"
            error={errors.description}
            control={control}
          />
        </FormSection>

        <FormSection title="seo">
          <TextFieldForm
            id="keywords"
            error={errors.description}
            control={control}
            multiline
          />
        </FormSection>

        <FormSection title="additionalInfo">
          {isAdmin && (
            <SelectFieldForm
              multiple
              id="tags"
              options={tags}
              error={errors.tags}
              control={control}
            />
          )}

          {isAdmin && (
            <SelectFieldForm
              id="priority"
              options={priorities}
              error={errors.priority}
              control={control}
            />
          )}
        </FormSection>

        <FormSection title="time">
          {values.dates.map((item, index) => (
            <div key={item.id}>
              <TextFieldForm
                title="time"
                disabled
                id={`dates[${index}].start`}
                control={control}
              />
            </div>
          ))}
        </FormSection>

        <Button
          ref={submitRef}
          fullWidth
          variant="contained"
          type="submit"
          hidden
        >
          {t('btn.reset')}
        </Button>
      </MyForm>

      {values.id && (
        <Divider
          onClick={removeEvent}
          title="btn.remove"
          textColor="#D10000"
          cursor="pointer"
        />
      )}
    </div>
  );
}

Event.propTypes = {
  onSubmit: func.isRequired,
  submitRef: object.isRequired,
  removeEvent: func,
  values: object,
};

Event.defaultProps = {
  values: {},
  removeEvent: () => {
  },
};

export default Event;
