import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import * as styles from '../styles';

import EventFilter from './Filter';
import EventSearch from './Search';

const InputBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& .MuiBox-root': {
      margin: '10px auto',
    },
  },
}));

function Options() {
  return (
    <div style={styles.optionContainer}>
      <InputBox>
        <EventFilter />
        <EventSearch />
      </InputBox>
    </div>
  );
}

export default Options;
