import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';

export const getEvent = createAsyncThunk('event/get', api.event.getEvent);
export const updateEvent = createAsyncThunk('event/update', api.event.updateEvent);
export const updateEventStatus = createAsyncThunk('event/change_status', api.event.updateEventStatus);
export const eventRemove = createAsyncThunk('event/remove', api.event.removeEvent);
export const getAllEvents = createAsyncThunk('events/GET_ALL_EVENT', api.event.getAllEvents);
export const getAllEventsOnReview = createAsyncThunk('events/all', api.event.getAllEventsOnReview);
export const sendEventToTelegram = createAsyncThunk('event/toTelegram', api.event.toTelegram);
