import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { string } from 'prop-types';

import { LOGIN_PAGE, REGISTRATION_SECOND_PAGE } from 'constants/locations';
import { auth } from 'services/firebase';
import { getOrganizationName } from 'store/profile/selectors';

function PrivateRoute({ redirectPath = LOGIN_PAGE }) {
  const orgName = useSelector(getOrganizationName);
  const isLoggedIn = auth.currentUser;

  if (isLoggedIn && !orgName) {
    return <Navigate replace to={REGISTRATION_SECOND_PAGE} />;
  }

  if (!isLoggedIn) {
    return <Navigate replace to={redirectPath} />;
  }

  return <Outlet />;
}

PrivateRoute.propTypes = {
  redirectPath: string,
};

PrivateRoute.defaultProps = {
  redirectPath: '',
};

export default PrivateRoute;
