import Container from '@mui/material/Container';

import Breadcrumbs from 'components/Breadcrumbs';
import EventsList from 'components/EventsList';

import HeaderPart from './components/HeaderPart';
import Options from './components/Options';

function Events() {
  return (
    <Container>
      <Breadcrumbs current="events" />
      <HeaderPart />
      <Options />
      <div style={{ marginTop: '12px' }}>
        <EventsList />
      </div>
    </Container>
  );
}

export default Events;
