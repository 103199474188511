export const infoContainer = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  alignItems: 'flex-start',

  maxWidth: '700px',
  marginTop: '52px',
};

export const description = {
  margin: '12px 0',
};
