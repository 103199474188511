import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import * as yup from 'yup';

import AuthTitle from 'components/AuthTitle';
import MyForm from 'components/Form';
import MaskTextFieldForm from 'components/formFields/MaskTextFieldForm';
import SelectFieldForm from 'components/formFields/SelectFieldForm';
import TextFieldForm from 'components/formFields/TextFieldForm';
import FormSection from 'components/FormSection';
import { city, country } from 'constants/common';
import { updateOrganization } from 'store/profile/actions';
import { getOrganizationId } from 'store/profile/selectors';
import Button from 'UIComponent/Button';

import * as styles from './styles';
import { organizationSchema } from './validations';

const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const FormSectionBlock = styled('div')(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('lg')]: {
    width: '49%',
  },
}));

const AddressBlock = styled('div')(({ theme }) => ({
  display: 'grid',
  gridGap: '1rem',

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

function SecondRegistration() {
  const id = useSelector(getOrganizationId);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors = {} },
  } = useForm({
    resolver: yupResolver(yup.object(organizationSchema)),
  });

  const onSubmit = (data) => {
    dispatch(updateOrganization({
      id,
      ...data,
    })).then(() => {
      toast.success(t('notification.finishRegistration'));
    });
  };

  return (
    <Container style={styles.mainContainer}>
      <AuthTitle title="secondRegistration" subTitle="secondRegistrationSubTitle" />

      <MyForm onSubmit={handleSubmit(onSubmit)}>

        <FormContainer>
          <FormSectionBlock>
            <FormSection title="mainInfo" styles={styles.mainInfoContainer}>
              <TextFieldForm
                required
                id="name"
                error={errors.name}
                control={control}
              />

              <TextFieldForm
                id="description"
                error={errors.description}
                control={control}
                multiline
                minRows={6}
              />
            </FormSection>
          </FormSectionBlock>
          <FormSectionBlock>

            <FormSection title="address" styles={styles.mainInfoContainer}>
              <AddressBlock>
                <SelectFieldForm
                  id="country"
                  options={country}
                  control={control}
                />

                <SelectFieldForm
                  id="city"
                  options={city}
                  control={control}
                />
              </AddressBlock>

              <TextFieldForm
                id="street"
                error={errors.street}
                control={control}
                fullWidth
              />

            </FormSection>

            <FormSection title="contacts" styles={styles.mainInfoContainer}>
              <MaskTextFieldForm
                id="phone"
                error={errors.phone}
                control={control}
                mask="+380 (99) 999 9999"
              />
            </FormSection>
          </FormSectionBlock>
        </FormContainer>

        <div style={styles.info}>{t('infoFieldRequired')}</div>

        <div>
          <Button
            variant="contained"
            type="submit"
          >
            { t('btn.finalRegistrationStep') }
          </Button>
        </div>

      </MyForm>
    </Container>
  );
}

export default SecondRegistration;
