import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { styled } from '@mui/material/styles';

import { color } from 'styles/text';

const Breadcrumbs = styled(MuiBreadcrumbs)(() => ({
  a: {
    color: color.breadcrumb,
    opacity: 0.5,
    ':hover': {
      textDecoration: 'underline',
    },
  },
}));

export default Breadcrumbs;
