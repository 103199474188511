import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  bool, number, object, string,
} from 'prop-types';

import MyTextField from '../Material/TextField';

function TextFieldForm(props) {
  const {
    control,
    error,
    type,
    multiline,
    id,
    disabled,
    required,
    fullWidth,
    minRows,
    title,
  } = props;
  const { t } = useTranslation();
  const { message } = error;
  const err = message ? t(`error.${message}`) : '';

  return (
    <Controller
      control={control}
      name={id}
      render={({ field: { value, onChange } }) =>
        (
          <MyTextField
            required={required}
            type={type}
            label={t(`form.${title || id}`)}
            placeholder={t(`form.placeholder.${title || id}`)}
            error={err}
            multiline={multiline}
            minRows={minRows}
            onChange={onChange}
            value={value}
            disabled={disabled}
            fullWidth={fullWidth}
          />
        )}
    />
  );
}

TextFieldForm.propTypes = {
  control: object.isRequired,
  id: string.isRequired,
  error: object,
  type: string,
  minRows: number,
  multiline: bool,
  disabled: bool,
  required: bool,
  fullWidth: bool,
};

TextFieldForm.defaultProps = {
  error: {},
  type: 'text',
  minRows: 3,
  multiline: false,
  required: false,
  disabled: false,
  fullWidth: false,
};

export default TextFieldForm;
