import React from 'react';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';

import NavigationLink from 'components/NavigationLink';

import * as styles from './styles';

function AuthLink(props) {
  const { question, text, link } = props;
  const { t } = useTranslation();
  return (
    <NavigationLink link={link}>
      <div style={styles.container}>
        <div style={styles.question}>{t(`auth.${question}`)}</div>
        <div style={styles.mainText}>{t(`auth.${text}`)}</div>
      </div>
    </NavigationLink>
  );
}

AuthLink.propTypes = {
  question: string.isRequired,
  text: string.isRequired,
  link: string.isRequired,
};

export default AuthLink;
