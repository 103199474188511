import { styled } from '@mui/material/styles';

const openedMixin = (theme) =>
  ({
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    right: 0,
  });

const closedMixin = (theme) =>
  ({
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    right: '900px',
  });

const BackDrop = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'open',
})(
  ({ theme, open }) =>
    ({
      position: 'absolute',
      backgroundColor: '#010101',
      top: '-24px',
      left: '-24px',
      bottom: '-24px',
      opacity: '0.5',
      ...(open && {
        ...openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
      }),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    }),
);

export default BackDrop;
