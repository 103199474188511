import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { string } from 'prop-types';

import { EVENTS_PAGE } from 'constants/locations';
import { removeFilesInFolder } from 'services/firebase/storage';
import { eventRemove } from 'store/events/actions';
import StyledMenu from 'UIComponent/ItemListSetting';

import styles from './styles';

function Setting({ id }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const removeEvent = (e) => {
    e.preventDefault();
    const folderUrl = `event/${id}`;
    dispatch(eventRemove(id))
      .then(() => removeFilesInFolder(folderUrl))
      .then(() => navigate(EVENTS_PAGE))
      .then(() => toast.success(t('notification.eventRemoved')));
  };

  return (
    <div style={styles.settingContainer} onClick={(e) => e.preventDefault()}>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(e) => {
          e.preventDefault();
          handleClick(e);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={removeEvent} disableRipple>
          <DeleteIcon color="error" />
          {t('remove')}
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

Setting.propTypes = {
  id: string.isRequired,
};

export default Setting;
