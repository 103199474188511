import MuiListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';

const ListItemButton = styled(MuiListItemButton)(({ theme, open: isOpen, active }) =>
  ({
    width: isOpen ? '200px' : '48px',
    flexGrow: '0',
    height: '48px',
    backgroundColor: active ? 'rgba(0, 132, 255, 0.1)' : 'transparent',
    borderRadius: '8px',
    justifyContent: 'center',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    '&:hover': {
      backgroundColor: active ? 'rgba(103, 103, 103, 0.3)' : 'rgba(103, 103, 103, 0.1)',
    },
  }));

export default ListItemButton;
