import React from 'react';
import { useTranslation } from 'react-i18next';
import ListItem from '@mui/material/ListItem';
import {
  bool, func, node, string,
} from 'prop-types';

import ListItemButton from 'UIComponent/DrawerListItemButton';
import ListItemIcon from 'UIComponent/DrawerListItemIcon';
import ListItemText from 'UIComponent/DrawerListItemText';

import * as styles from '../styles';

function DrawerItem(props) {
  const {
    onClick, isOpen, icon, title, isActive,
  } = props;
  const { t } = useTranslation();

  return (
    <ListItem disablePadding style={styles.listItem} onClick={onClick}>
      <ListItemButton open={isOpen} active={isActive ? 1 : 0}>
        <ListItemIcon open={isOpen} active={isActive ? 1 : 0}>
          {icon}
        </ListItemIcon>
        <ListItemText
          open={isOpen}
          primary={t(`menu.${title}`)}
        />
      </ListItemButton>
    </ListItem>
  );
}

DrawerItem.propTypes = {

  isOpen: bool.isRequired,
  title: string.isRequired,
  onClick: func,
  icon: node,
  isActive: bool,
};

DrawerItem.defaultProps = {
  icon: null,
  isActive: false,
  onClick: () => {},
};

export default DrawerItem;
