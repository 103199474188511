import React from 'react';
import CheckBoxOutlineBlankOutlinedIcon
  from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { FormControlLabel } from '@mui/material';
import MuiCheckbox from '@mui/material/Checkbox';
import { func, string } from 'prop-types';

import * as styles from './styles';

function Checkbox(props) {
  const {
    title, onChange, name, value,
  } = props;
  return (
    <div style={styles.container}>
      <FormControlLabel
        label={title}
        labelPlacement="end"
        onChange={onChange}
        value={value}
        name={name}
        control={(
          <MuiCheckbox
            icon={<CheckBoxOutlineBlankOutlinedIcon />}
            checkedIcon={<CheckBoxOutlinedIcon />}
            label={title}
            labelPlacement="end"
          />
        )}
      />
    </div>
  );
}

Checkbox.propTypes = {
  title: string,
  onChange: func,
  value: string,
  name: string,
};

Checkbox.defaultProps = {
  title: '',
  name: '',
  onChange: () => {},
  value: '',
};

export default Checkbox;
