import { color } from '../../styles/text';

export const container = {
  display: 'flex',
};

export const svg = {
  paddingLeft: 0,
};

export const text = {
  color: color.lightGray,
  display: 'flex',
  alignItems: 'center',
};
