import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';

import DashboardCard from 'components/DashboardCard';
import { EVENTS_PAGE } from 'constants/locations';
import { getEventCounter } from 'store/profile/selectors';

function EventCounterCard() {
  const { t } = useTranslation();
  const countEvents = useSelector(getEventCounter);

  if (countEvents === 0) return null;

  return (
    <DashboardCard link={EVENTS_PAGE}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '100%',
      }}
      >
        <div />
        <Typography
          variant="h2"
          style={{
            color: 'black',
            fontWeight: 'bold',
          }}
        >
          {countEvents}
        </Typography>
        <Typography variant="h6" style={{ color: '#0084FF', textAlign: 'center' }}>
          {t('dashboard.counterAllEvent')}
        </Typography>
      </div>
    </DashboardCard>
  );
}

export default EventCounterCard;
