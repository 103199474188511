export const mapperToProfile = (data) => {
  const {
    email, isVerified, id, organization, type, socials, role,
  } = data;

  return {
    id,
    organization: {
      id: organization.id,
      ...organization,
    },
    socials,
    account: {
      email,
      isVerified,
      type,
      admin: role === 'admin',
    },
  };
};
