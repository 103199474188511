import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  array, bool, object, string,
} from 'prop-types';

import Select from 'components/Select';

function SelectFieldForm(props) {
  const { t } = useTranslation();
  const {
    control,
    error = {},
    id,
    options,
    disabled,
    multiple,
  } = props;

  return (
    <Controller
      control={control}
      name={id}
      render={({ field: { onChange, value } }) =>
        (
          <Select
            multiple={multiple}
            value={value}
            onChange={onChange}
            label={t(`form.${id}`)}
            placeholder={t(`form.placeholder.${id}`)}
            error={error}
            options={options}
            disabled={disabled}
          />
        )}
    />
  );
}

SelectFieldForm.propTypes = {
  control: object.isRequired,
  error: object,
  id: string.isRequired,
  options: array,
  disabled: bool,
  multiple: bool,
};

SelectFieldForm.defaultProps = {
  error: {},
  options: [],
  disabled: false,
  multiple: false,
};

export default SelectFieldForm;
