import React from 'react';
import { styled } from '@mui/material/styles';

import PageTitle from 'components/PageTitle';

const HeaderPartContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

function HeaderPart() {
  return (
    <HeaderPartContainer>
      <PageTitle title="events" />
    </HeaderPartContainer>
  );
}

export default HeaderPart;
