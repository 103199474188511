import React from 'react';
import {
  bool, element, object, string,
} from 'prop-types';

import Link from 'UIComponent/Link';

function NavigationLink(props) {
  const {
    link, title, disabled, children, style,
  } = props;
  return (
    <Link
      to={link}
      type="button"
      disabled={disabled}
      style={style}
    >
      {children || title}
    </Link>
  );
}

NavigationLink.propTypes = {
  link: string.isRequired,
  title: string,
  disabled: bool,
  children: element,
  style: object,
};

NavigationLink.defaultProps = {
  title: 'false',
  disabled: false,
  children: null,
  style: {},
};

export default NavigationLink;
