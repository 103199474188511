export const color = {
  black: '#000000',
  white: '#ffffff',
  lightGray: '#676767',
  divider: '#CBCBCB',
  breadcrumb: 'rgba(0, 0, 0, 0.87)',

  primary: '#0084FF',
};

export const width = {
  bold: 600,
};
