import styled from '@emotion/styled';
import Box from '@mui/material/Box';

export const ModalMenuIconBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
}));
