import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

import { color } from 'styles/text';

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'open',
})(({ theme }) =>
  ({
    zIndex: theme.zIndex.drawer + 1,
    background: color.white,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }));
