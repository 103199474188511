import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';

import EventsList from 'components/EventsList';
import { FILTER_EVENTS_STATUS } from 'constants/common';
import { resetEvents, setEventsFilter } from 'store/events';
import { getEventsCountOnReview } from 'store/events/selectors';

function DraftEvents() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const countEventsOnReview = useSelector(getEventsCountOnReview);

  useEffect(() => {
    dispatch(setEventsFilter(FILTER_EVENTS_STATUS.ON_REVIEW));
    return () => {
      dispatch(resetEvents());
    };
  }, []);

  return (
    <div style={{ marginTop: 8 }}>
      <Typography style={{ margin: '20px 0', color: '#003566' }} variant="h5">
        {countEventsOnReview ? t('dashboard.reviewList') : ''}
      </Typography>
      <EventsList hideEmptyText />
    </div>
  );
}

export default DraftEvents;
