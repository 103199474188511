import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import MyForm from 'components/Form';
import TextFieldForm from 'components/formFields/TextFieldForm';
import RememberMe from 'components/RememberMe';
import { checkError } from 'helpers/error';
import { login } from 'services/firebase/auth';
import Button from 'UIComponent/Button';

import { loginSchema } from '../validations';

function Form() {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors = {} },
  } = useForm({
    resolver: yupResolver(yup.object(loginSchema)),
  });

  const onSubmit = (data) => {
    login(data).catch(({ code }) => {
      const { field, message } = checkError(code);
      setError(field, { type: 'change', message });
    });
  };

  return (
    <MyForm onSubmit={handleSubmit(onSubmit)}>

      <TextFieldForm
        id="email"
        error={errors.email}
        control={control}
      />

      <TextFieldForm
        id="password"
        type="password"
        error={errors.password}
        control={control}
      />

      <RememberMe control={control} />

      <Button
        fullWidth
        variant="contained"
        type="submit"
      >
        { t('btn.login') }
      </Button>
    </MyForm>
  );
}

export default Form;
