import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate, Route, Routes,
} from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';

import AuthLayout from 'components/layouts/AuthLayout';
import ContentLayout from 'components/layouts/ContentLayout';
import SetupLayout from 'components/layouts/SetupLayout';
import Spinner from 'components/Spinner';
import {
  DASHBOARD_PAGE,
  EVENT_BY_ID_PAGE,
  EVENTS_PAGE,
  LOGIN_PAGE,
  PRIVACY_POLICE_PAGE,
  REGISTRATION_PAGE,
  REGISTRATION_SECOND_PAGE,
  RESET_PASSWORD_PAGE,
  REVIEW_EVENTS_PAGE,
  SETTING_ACCOUNT_PAGE,
  SETTING_ORGANIZATION_PAGE,
  SETTING_REMOVE_PAGE,
} from 'constants/locations';
import Dashboard from 'pages/Dashboard';
import Event from 'pages/Event';
import Events from 'pages/Events';
import Login from 'pages/Login';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import Registration from 'pages/Registration';
import ResetPassword from 'pages/ResetPassword';
import ReviewEvents from 'pages/ReviewEvents';
import SecondRegistration from 'pages/SecondRegistration';
import SettingAccount from 'pages/SettingAccount';
import SettingOrganization from 'pages/SettingOrganization';
import SettingRemove from 'pages/SettingRemove';
import { auth } from 'services/firebase';
import { getProfile } from 'store/profile/actions';

import AuthRoute from './components/AuthRoute';
import PrivateRoute from './components/PrivateRoute';
import SetupRouter from './components/SetupRouter';
import SuperAdmin from './components/SuperAdmin';

function Navigation() {
  const [isLogin, setStatus] = useState(null);
  const dispatch = useDispatch();
  const id = useSelector((state) => state.profile.id);

  useEffect(() => {
    onAuthStateChanged(
      auth,
      (user) => {
        setStatus(!!user);
      },
      () =>
        setStatus(false),
    );
  }, []);

  useEffect(() => {
    if (isLogin) {
      dispatch(getProfile());
    }
  }, [isLogin]);

  if (isLogin === null) {
    return <Spinner isShow fullScreen />;
  }

  if (!!isLogin && !id) {
    return (<Spinner isShow title="loadAccount" fullScreen />);
  }

  const defaultRedirectPage = isLogin ? DASHBOARD_PAGE : LOGIN_PAGE;

  return (
    <Routes>

      <Route
        path={PRIVACY_POLICE_PAGE}
        element={<PrivacyPolicy />}
      />

      <Route element={<AuthRoute />}>
        <Route
          path={LOGIN_PAGE}
          element={(
            <AuthLayout>
              <Login />
            </AuthLayout>
          )}
        />
      </Route>

      <Route element={<AuthRoute />}>
        <Route
          path={RESET_PASSWORD_PAGE}
          element={(
            <AuthLayout>
              <ResetPassword />
            </AuthLayout>
          )}
        />
      </Route>

      <Route element={<AuthRoute />}>
        <Route
          path={REGISTRATION_PAGE}
          element={(
            <AuthLayout>
              <Registration />
            </AuthLayout>
          )}
        />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route
          path={SETTING_ACCOUNT_PAGE}
          element={(
            <ContentLayout>
              <SettingAccount />
            </ContentLayout>
          )}
        />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route
          path={SETTING_ORGANIZATION_PAGE}
          element={(
            <ContentLayout>
              <SettingOrganization />
            </ContentLayout>
          )}
        />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route
          path={SETTING_REMOVE_PAGE}
          element={(
            <ContentLayout>
              <SettingRemove />
            </ContentLayout>
          )}
        />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route
          path={DASHBOARD_PAGE}
          element={(
            <ContentLayout>
              <Dashboard />
            </ContentLayout>
          )}
        />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route
          path={EVENTS_PAGE}
          element={(
            <ContentLayout>
              <Events />
            </ContentLayout>
          )}
        />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route
          path={EVENT_BY_ID_PAGE}
          element={(
            <ContentLayout>
              <Event />
            </ContentLayout>
          )}
        />
      </Route>

      <Route element={<SetupRouter redirectPath={defaultRedirectPage} />}>
        <Route
          path={REGISTRATION_SECOND_PAGE}
          element={(
            <SetupLayout>
              <SecondRegistration />
            </SetupLayout>
          )}
        />
      </Route>

      <Route element={<SuperAdmin redirectPath={defaultRedirectPage} />}>
        <Route
          path={REVIEW_EVENTS_PAGE}
          element={(
            <ContentLayout>
              <ReviewEvents />
            </ContentLayout>
          )}
        />
      </Route>

      <Route
        path="*"
        element={<Navigate to={defaultRedirectPage} replace />}
      />
    </Routes>
  );
}

export default Navigation;
