import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DeleteIcon from '@mui/icons-material/Delete';
import EventIcon from '@mui/icons-material/Event';

export const LOGIN_PAGE = '/login';
export const REGISTRATION_PAGE = '/registration';
export const RESET_PASSWORD_PAGE = '/resetPassword';
export const DASHBOARD_PAGE = '/dashboard';
export const EVENTS_PAGE = '/events';
export const EVENT_PAGE = '/event';
export const EVENT_BY_ID_PAGE = `${EVENT_PAGE}/:eventId`;
export const PRIVACY_POLICE_PAGE = '/privacy';
export const SETTING_PAGE = '/setting';
export const SETTING_ACCOUNT_PAGE = `${SETTING_PAGE}/account`;
export const SETTING_ORGANIZATION_PAGE = `${SETTING_PAGE}/organization`;
export const SETTING_REMOVE_PAGE = `${SETTING_PAGE}/remove`;
export const REGISTRATION_SECOND_PAGE = '/registration/second';
export const REVIEW_EVENTS_PAGE = '/review/events';

export const isSettingRouts = {
  [SETTING_ACCOUNT_PAGE]: true,
  [SETTING_ORGANIZATION_PAGE]: true,
  [SETTING_REMOVE_PAGE]: true,
};

export const DRAWER_APP_MENU = [
  {
    icon: <DashboardIcon />, title: 'dashboard', link: DASHBOARD_PAGE,
  },
  {
    icon: <EventIcon />, title: 'events', link: EVENTS_PAGE,
  },
];

export const DRAWER_SETTING_MENU = [
  {
    icon: <AccountCircleIcon />, title: 'settingAccount', link: SETTING_ACCOUNT_PAGE,
  },
  {
    icon: <AssignmentIndIcon />, title: 'settingOrganization', link: SETTING_ORGANIZATION_PAGE,
  },
  {
    icon: <DeleteIcon />, title: 'settingRemove', link: SETTING_REMOVE_PAGE,
  },
];
