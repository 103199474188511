import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0084FF',
    },
    secondary: {
      main: '#E0E0E0',
      contrastText: '#000',
    },
  },
  config: {
    drawerWidth: 248,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: () =>
        ({
          a: {
            textDecoration: 'none',
            color: 'inherit',
          },
        }),
    },
  },
});

export default theme;
