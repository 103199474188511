import React from 'react';
import { useTranslation } from 'react-i18next';
import { node, object, string } from 'prop-types';

import FormBox from 'UIComponent/FormBox';

import { styleTitle } from './styles';

function FormSection(props) {
  const { styles, title, children } = props;
  const { t } = useTranslation();

  return (
    <FormBox styles={styles}>
      <div style={styleTitle}>{t(`title.${title}`)}</div>
      {children}
    </FormBox>
  );
}

FormSection.propTypes = {
  children: node.isRequired,
  title: string,
  styles: object,
};

FormSection.defaultProps = {
  title: '',
  styles: {},
};

export default FormSection;
