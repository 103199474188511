import { styled } from '@mui/material/styles';

const DrawerWrapperItems = styled('div')(() =>
  ({
    height: '100%',
    display: 'grid',
    grid: 1,
    gridAutoRows: '1fr auto',
    paddingBottom: '12px',
  }));

export default DrawerWrapperItems;
