import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import Routes from 'components/Routes';
import Spinner from 'components/Spinner';

import { store } from './store/store';
import theme from './styles/theme';

import 'react-toastify/dist/ReactToastify.css';

dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <Suspense fallback={<Spinner isShow />}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CssBaseline />
              <BrowserRouter>
                <ToastContainer
                  position="top-center"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop
                  rtl={false}
                  draggable
                  theme="light"
                />
                <Routes />
              </BrowserRouter>
            </LocalizationProvider>
          </ThemeProvider>
        </Suspense>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
