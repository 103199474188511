import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Typography from '@mui/material/Typography';
import { bool, string } from 'prop-types';

import * as styles from './styles';

function AuthTitle(props) {
  const { title, subTitle, isBack } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goBack = () =>
    navigate(-1);

  return (
    <div style={styles.container}>
      {isBack && (
        <div style={styles.btnBack} onClick={goBack}>
          <ArrowBackIosIcon fontSize="small" />
          {t('back')}
        </div>
      )}
      <Typography style={styles.title}>
        {t(`auth.${title}`)}
      </Typography>
      <Typography style={styles.subTitle}>
        {t(`auth.${subTitle}`)}
      </Typography>
    </div>
  );
}

AuthTitle.propTypes = {
  title: string.isRequired,
  subTitle: string,
  isBack: bool,
};

AuthTitle.defaultProps = {
  subTitle: '',
  isBack: false,
};

export default AuthTitle;
