import { configureStore } from '@reduxjs/toolkit';

import events from 'store/events';
import profile from 'store/profile';

export const store = configureStore({
  reducer: {
    profile,
    events,
  },
});
