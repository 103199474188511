import { func, node, object } from 'prop-types';

import FormBox from 'UIComponent/FormBox';

function Form(props) {
  const { onSubmit, styles, children } = props;
  return (
    <FormBox
      noValidate
      component="form"
      onSubmit={onSubmit}
      styles={styles}
    >
      {children}
    </FormBox>
  );
}

Form.propTypes = {
  children: node.isRequired,
  onSubmit: func,
  styles: object,
};

Form.defaultProps = {
  onSubmit: () => {},
  styles: {},
};

export default Form;
