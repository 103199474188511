import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { bool, object, string } from 'prop-types';

import Breadcrumbs from 'components/Breadcrumbs';
import PageTitle from 'components/PageTitle';

import * as styles from './styles';

function SettingHeader(props) {
  const {
    currentPage, title, showButton, submitRef,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumbs current={currentPage} />
      <div style={styles.container}>
        <PageTitle title={title} />
        {showButton && (
          <Button
            variant="contained"
            size="large"
            onClick={() => submitRef.current?.click()}
          >
            { t('btn.save') }
          </Button>
        )}
      </div>
    </>
  );
}

SettingHeader.propTypes = {
  title: string.isRequired,
  currentPage: string.isRequired,
  submitRef: object,
  showButton: bool,
};

SettingHeader.defaultProps = {
  showButton: false,
  submitRef: {},
};

export default SettingHeader;
