export function getTranslations(params) {
  const { translations, lang, preKey = '' } = params;
  return Object.entries(translations).reduce((acc, [key, value]) => {
    const generateCodeTranslation = preKey ? `${preKey}.${key}` : key;
    if (value.children) {
      return {
        ...acc,
        ...getTranslations({ translations: value.children, lang, preKey: generateCodeTranslation }),
      };
    }

    return {
      ...acc,
      [generateCodeTranslation]: value[lang],
    };
  }, {});
}
