import {
  getAllEvents,
  getAllEventsOnReview,
  getEvent,
  removeEvent,
  toTelegram,
  updateEvent,
  updateEventStatus,
} from './methods/event';
import {
  getProfile,
  updateOrganization,
} from './methods/profile';

export default {
  profile: {
    getProfile,
    updateOrganization,
  },
  event: {
    getAllEvents,
    getEvent,
    updateEvent,
    updateEventStatus,
    removeEvent,
    getAllEventsOnReview,
    toTelegram,
  },
};
