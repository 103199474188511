import { color } from '../../styles/text';

export const fullContainer = {
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

export const title = {
  marginTop: '8px',
  color: color.lightGray,
  fontSize: '20px',
};

export const container = {
  width: '100%',
  padding: '12px 0',
  textAlign: 'center',
};
