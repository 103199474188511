import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-input-mask';
import TextField from '@mui/material/TextField';
import { bool, object, string } from 'prop-types';

function MaskTextFieldForm(props) {
  const {
    control,
    error,
    type,
    id,
    disabled,
    mask,
    maskChar,
    required,
  } = props;

  const { t } = useTranslation();
  const { message } = error;
  const err = message ? t(`error.${message}`) : '';

  return (
    <Controller
      control={control}
      name={id}
      render={({ field: { value, onChange } }) =>
        (
          <MaskedInput
            mask={mask}
            maskPlaceholder={maskChar}
            value={value}
            onChange={onChange}
            disabled={disabled}
          >
            <TextField
              required={required}
              variant="outlined"
              autoComplete="off"
              error={!!err}
              helperText={err}
              label={t(`form.${id}`)}
              placeholder={t(`form.placeholder.${id}`)}
              type={type}
            />
          </MaskedInput>
        )}
    />
  );
}

MaskTextFieldForm.propTypes = {
  control: object.isRequired,
  id: string.isRequired,
  mask: string.isRequired,
  error: object,
  type: string,
  maskChar: string,
  required: bool,
  disabled: bool,
};

MaskTextFieldForm.defaultProps = {
  error: {},
  type: 'text',
  disabled: false,
  required: false,
  maskChar: '',
};

export default MaskTextFieldForm;
