import { Navigate, Outlet } from 'react-router-dom';
import { string } from 'prop-types';

import { DASHBOARD_PAGE } from 'constants/locations';
import { auth } from 'services/firebase';

function AuthRoute({ redirectPath = DASHBOARD_PAGE }) {
  const isLoggedIn = auth.currentUser;

  if (isLoggedIn) {
    return <Navigate replace to={redirectPath} />;
  }

  return <Outlet />;
}

AuthRoute.propTypes = {
  redirectPath: string,
};

AuthRoute.defaultProps = {
  redirectPath: '',
};

export default AuthRoute;
