import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import SecurityIcon from '@mui/icons-material/Security';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { bool, func } from 'prop-types';

import {
  DRAWER_APP_MENU, DRAWER_SETTING_MENU, isSettingRouts, REVIEW_EVENTS_PAGE,
} from 'constants/locations';
import { logout } from 'services/firebase/auth';
import { getIsAdmin } from 'store/profile/selectors';
import MyDrawer from 'UIComponent/Drawer';
import DrawerHeader from 'UIComponent/DrawerHeader';
import DrawerWrapperItem from 'UIComponent/DrawerWrapperItem';

import DrawerItem from './components/DrawerItem';

function Drawer(props) {
  const { isOpen, setIsOpen } = props;
  const isAdmin = useSelector(getIsAdmin);

  const { pathname } = useLocation();

  const drawerMenu = isSettingRouts[pathname] ? DRAWER_SETTING_MENU : DRAWER_APP_MENU;

  return (
    <MyDrawer variant="permanent" anchor="left" open={isOpen}>
      <DrawerHeader>
        <IconButton onClick={() => setIsOpen((state) => !state)}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerWrapperItem>
        <List>
          {drawerMenu.map((item) => (
            <NavLink to={item.link} key={item.link}>
              {({ isActive }) =>
                (
                  <DrawerItem
                    isOpen={isOpen}
                    isActive={isActive}
                    title={item.title}
                    icon={item.icon}
                  />
                )}
            </NavLink>
          ))}
        </List>
        <div>
          { isAdmin && (
            <NavLink to={REVIEW_EVENTS_PAGE}>
              {({ isActive }) =>
                (
                  <DrawerItem
                    isActive={isActive}
                    isOpen={isOpen}
                    icon={<SecurityIcon />}
                    title="review"
                  />
                )}
            </NavLink>
          )}

          <DrawerItem
            onClick={logout}
            isOpen={isOpen}
            icon={<LogoutIcon />}
            title="logout"
          />
        </div>
      </DrawerWrapperItem>
    </MyDrawer>
  );
}

Drawer.propTypes = {
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
};

export default memo(Drawer);
