export const eventMapperToServer = ({ ...body }) => ({
  id: body.eventId,
  ...body,
  ...(body.street && { street: body.street.trim() }),
  ...(body.description && { description: body.description.trim() }),
  ...(body.name && { name: body.name.trim() }),
  ...(body.place && { place: body.place.trim() }),
  ...(body.instagram && { instagram: body.instagram.trim() }),
  ...(body.telegram && { telegram: body.telegram.trim() }),
  ...(body.website && { website: body.website.trim() }),
  ...(body?.prices?.length && { priceList: body.prices }),
  location: body.location?.length ? [body.location.x, body.location.y] : [],
});
