import Paper from '@mui/material/Paper';
import { object, string } from 'prop-types';

import photo from 'assets/svg/photo.svg';

const width = 426;
const height = 256;

function EventImage(props) {
  const { fileRef, url } = props;

  return (
    <div>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <div
          onClick={() => fileRef.current?.click()}
          style={{
            background: '#F7F7F7',
            width,
            height,
          }}
        >
          {
            url ? (
              <Paper
                elevation={8}
                style={{
                  cursor: 'pointer',
                  overflow: 'hidden',
                  borderRadius: 8,
                }}
              >
                <img
                  src={url}
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width,
                    height,
                  }}
                  alt=""
                />
              </Paper>
            ) : (
              <div style={{
                display: 'flex',
                width,
                height,
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                border: '1px dashed #969696',
                borderRadius: '4px',
              }}
              >
                <div><img src={photo} alt="..." /></div>
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#0084FF',
                    cursor: 'pointer',
                  }}
                >
                  Оберіть з комп’ютера
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

EventImage.propTypes = {
  fileRef: object.isRequired,
  url: string.isRequired,
};

export default EventImage;
