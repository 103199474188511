import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { getEventCounter } from 'store/profile/selectors';

import DraftEvents from './components/DraftEvents';
import EventCounterCard from './components/EventCounterCard';
import EventOriginalCounterCard from './components/EventOriginalCounterCard';
import * as styles from './styles';

const CardsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridGap: '1rem',
  gridTemplateColumns: 'repeat(1, 1fr)',
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(5, 1fr)',
  },
}));

function Dashboard() {
  const { t } = useTranslation();
  const countEvents = useSelector(getEventCounter);

  return (
    <Container>
      <div style={styles.textContainer}>
        <Typography variant="h4" component="h4">
          {t('dashboard.welcomeTitle')}
        </Typography>
        {!countEvents && (
          <Typography variant="h6" component="h6">
            { t('dashboard.welcomeSubTitle') }
          </Typography>
        )}
      </div>

      <CardsContainer>
        <EventCounterCard />
        <EventOriginalCounterCard />
      </CardsContainer>

      <DraftEvents />
    </Container>
  );
}

export default Dashboard;
