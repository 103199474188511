import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';

import MyForm from 'components/Form';
import SelectFieldForm from 'components/formFields/SelectFieldForm';
import TextFieldForm from 'components/formFields/TextFieldForm';
import FormSection from 'components/FormSection';
import SettingHeader from 'components/SettingHeader';
import { accountType } from 'constants/common';
import { getAccountSetting } from 'store/profile/selectors';
import Button from 'UIComponent/Button';

import * as styles from './styles';

function SettingAccount() {
  const submitRef = useRef();
  const account = useSelector(getAccountSetting);

  const {
    control,
    formState: { errors = {} },
  } = useForm({
    defaultValues: {
      email: account.email,
      type: account.type,
    },
  });

  return (
    <Container>
      <SettingHeader
        submitRef={submitRef}
        currentPage="settingAccount"
        title="settingAccount"
      />
      <MyForm styles={styles.formBuilderContainer}>

        <FormSection
          title="mainInfo"
          styles={styles.mainInfoContainer}
        >
          <TextFieldForm
            id="email"
            disabled
            error={errors.name}
            control={control}
          />

          <SelectFieldForm
            id="type"
            disabled
            options={accountType}
            control={control}
          />
        </FormSection>

        <Button
          ref={submitRef}
          fullWidth
          type="submit"
          hidden
        />

      </MyForm>
    </Container>
  );
}

export default SettingAccount;
