import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';

import { getUrl } from 'helpers';
import { getLink } from 'services/firebase/storage';

import SettingButton from '../../SettingButton';
import * as styles from '../styles';

function User() {
  const organization = useSelector((state) =>
    state.profile.organization);
  const { name, id, logo } = organization;
  const [logoUrl, setLogoUrl] = useState('');

  useEffect(() => {
    if (logo) {
      const url = getUrl({ type: 'organizations', fileName: logo, id });
      getLink(url, setLogoUrl);
    }
  }, [logo]);

  return (
    <div style={styles.userContainer}>
      <Avatar sx={styles.userAvatar} src={logoUrl} variant="rounded" />
      <div style={styles.userName}>{name}</div>
      <SettingButton />
    </div>
  );
}

export default User;
