export const formBuilderContainer = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '38px',
};

export const mainInfoContainer = {
  minWidth: '700px',
};

export const countryCityName = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridColumnGap: '10px',
};

export const avatar = {
  width: '120px',
  height: '120px',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
};

export const addPhoto = {
  position: 'absolute',
  bottom: 0,
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '32px',
  width: '100%',
  background: 'rgba(88, 87, 87, 0.75)',
};

export const avatarImgContainer = {
  display: 'flex',
  alignItems: 'center',
  gridColumnGap: '10px',
  position: 'relative',
  borderRadius: '4px',
  cursor: 'pointer',
  width: '120px',
  height: '120px',
  overflow: 'hidden',
};

export const avatarContainer = {
  display: 'flex',
  alignItems: 'center',
};

export const avatarText = {
  marginLeft: '8px',
  fontSize: '16px',
  fontWeight: 600,
  color: '#676767',
};
