export const wrapperBtn = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '48px',
  height: '48px',
  backgroundColor: '#F3F3F3',
  border: '1px solid #E7E7E7',
  color: '#585757',
  borderRadius: '8px',
  padding: '12px',
  margin: '6px',
  cursor: 'pointer',
  '&:active': {
    animation: 'press 0.2s 1 linear',
  },
  '@keyframes press': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(0.92)',
    },
    to: {
      transform: 'scale(1)',
    },
  },
};

export const iconFlag = {
  width: '48px',
  margin: '8px',
  borderRadius: '5px',
};
