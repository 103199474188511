import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, string } from 'prop-types';

import * as styles from './styles';

function PageTitle(props) {
  const { title, useTranslate } = props;
  const { t } = useTranslation();
  return (
    <div style={styles.titlePage}>
      {useTranslate ? t(`title.${title}`) : title}
    </div>
  );
}

PageTitle.propTypes = {
  title: string,
  useTranslate: bool,
};

PageTitle.defaultProps = {
  title: '',
  useTranslate: true,
};

export default PageTitle;
