export default {
  auth: {
    children: {
      loginTitle: {
        en: 'Log in',
        ua: 'Вхід у систему',
      },
      loginSubTitle: {
        en: 'Enter your credentials',
        ua: 'Введіть свої дані для входу',
      },
      registerTitle: {
        en: 'Registration',
        ua: 'Реєстрація',
      },
      registerSubTitle: {
        en: 'Enter your credentials to sign up',
        ua: 'Введіть свої дані для реєстрації',
      },
      resetPasswordTitle: {
        en: 'Reset your password',
        ua: 'Відновлення паролю',
      },
      resetPasswordSubTitle: {
        en: 'Enter your email to reset a password',
        ua: 'Введіть свою електронну пошту для відновлення паролю',
      },
      secondRegistration: {
        en: 'Final registration',
        ua: 'Завершення реєстрації',
      },
      secondRegistrationSubTitle: {
        en: 'Step 2. Enter organization info',
        ua: 'Крок 2. Введіть дані організації',
      },
      dontHaveAccount: {
        en: 'Don’t have an account?',
        ua: 'Не маєте акаунту?',
      },
      haveAccount: {
        en: 'Already have an account?',
        ua: 'Вже маєте аккаунт?',
      },
      registration: {
        en: 'Sign up',
        ua: 'Зареєструватися',
      },
      login: {
        en: 'Log in',
        ua: 'Увійти',
      },
      remember: {
        en: 'Remember me',
        ua: "Запам'ятати мене",
      },
      forgotPassword: {
        en: 'Forgot your password?',
        ua: 'Забули пароль?',
      },
    },
  },
  form: {
    children: {
      status: {
        en: 'Status',
        ua: 'Status',
      },
      email: {
        en: 'Email',
        ua: 'Email',
      },
      password: {
        en: 'Password',
        ua: 'Пароль',
      },
      confirmPassword: {
        en: 'Confirm a password',
        ua: 'Підтвердити пароль',
      },
      orgName: {
        en: 'Organization’s name',
        ua: 'Назва організації',
      },
      name: {
        en: 'Name',
        ua: 'Назва',
      },
      description: {
        en: 'Description',
        ua: 'Опис',
      },
      ticketName: {
        en: 'Ticket name',
        ua: 'Назва квитка',
      },
      price: {
        en: 'Price',
        ua: 'Ціна',
      },
      cost: {
        en: 'UAH',
        ua: 'ГРН',
      },
      type: {
        en: 'Type',
        ua: 'Тип',
      },
      country: {
        en: 'Country',
        ua: 'Країна',
      },
      city: {
        en: 'City',
        ua: 'Місто',
      },
      street: {
        en: 'Address',
        ua: 'Адреса',
      },
      place: {
        en: 'Place',
        ua: 'Локація',
      },
      day: {
        en: 'Date',
        ua: 'Дата',
      },
      timeStart: {
        en: 'Start time',
        ua: 'Початок',
      },
      timeFinish: {
        en: 'Finish time',
        ua: 'Закінчення',
      },
      instagram: {
        en: 'Instagram nickname',
        ua: 'Instagram nickname',
      },
      telegram: {
        en: 'Telegram nickname',
        ua: 'Telegram nickname',
      },
      website: {
        en: 'Link',
        ua: 'Посилання',
      },
      phone: {
        en: 'Phone number',
        ua: 'Номер телефону',
      },
      eventSearch: {
        en: 'Search',
        ua: 'Пошук'
      },
      createBy: {
        en: 'Created By',
        ua: 'Создан'
      },
      tags: {
        en: 'Tags',
        ua: 'Теги'
      },
      keywords: {
        en: 'Keywords',
        ua: 'Пошукові слова'
      },
      priority: {
        en: 'Priority',
        ua: 'Приоритет'
      },
      time: {
        en: 'Time',
        ua: 'Час',
      },
      placeholder: {
        children: {
          status: {
            en: 'Select event status',
            ua: 'Введіть статус',
          },
          time: {
            en: 'Time',
            ua: 'Час',
          },
          ticketName: {
            en: 'Ticket name',
            ua: 'Назва квитка',
          },
          email: {
            en: 'Enter your email',
            ua: 'Введіть email',
          },
          password: {
            en: 'Enter your password',
            ua: 'Введіть пароль',
          },
          confirmPassword: {
            en: 'Confirm your password',
            ua: 'Підтвердіть пароль',
          },
          orgName: {
            en: 'Enter organization’s name',
            ua: 'Введіть назву організації',
          },
          name: {
            en: 'Enter name',
            ua: 'Введіть назву',
          },
          description: {
            en: 'Enter description',
            ua: 'Введіть опис',
          },
          price: {
            en: 'Enter price',
            ua: 'Введіть ціну',
          },
          cost: {
            en: 'Enter UAH',
            ua: 'Введіть ціну',
          },
          type: {
            en: "Select event's type",
            ua: 'Оберіть тип заходу',
          },
          street: {
            en: 'Enter street',
            ua: 'Введіть вулицю',
          },
          place: {
            en: 'Enter place',
            ua: 'Введіть локацію',
          },
          country: {
            en: 'Enter country',
            ua: 'Введіть країну',
          },
          city: {
            en: 'Enter city',
            ua: 'Введіть місто',
          },
          day: {
            en: 'Enter a date',
            ua: 'Введіть дату',
          },
          timeStart: {
            en: 'Enter start time',
            ua: 'Введіть час початку',
          },
          timeFinish: {
            en: 'Enter finish time',
            ua: 'Введіть час закінчення',
          },
          instagram: {
            en: 'Enter your Instagram nickname',
            ua: 'Введіть свій Instagram nickname',
          },
          telegram: {
            en: 'Enter your Telegram nickname',
            ua: 'Введіть свій Telegram nickname',
          },
          website: {
            en: 'Enter link',
            ua: 'Введіть посилання',
          },
          phone: {
            en: 'Enter your phone number',
            ua: 'Введіть свій номер телефону',
          },
          eventSearch: {
            en: 'Event search',
            ua: 'Пошук події',
          },
          createBy: {
            en: 'Event create by',
            ua: 'Введіть організатора',
          },
          tags: {
            en: 'Tags',
            ua: 'Теги',
          },
          keywords: {
            en: 'Keywords',
            ua: 'Пошукові слова'
          },
          priority: {
            en: 'Priority',
            ua: 'Приоритет'
          },
        },
      },
    },
  },
  socialMedia: {
    children: {
      enterWithGoogle: {
        en: 'Log in with Google',
        ua: 'Увійти за допомогою Google',
      },
    },
  },
  menu: {
    children: {
      dashboard: {
        en: 'Dashboard',
        ua: 'Головна',
      },
      events: {
        en: 'Events',
        ua: 'Заходи',
      },
      logout: {
        en: 'Logout',
        ua: 'Вийти',
      },
      settingAccount: {
        en: 'Account',
        ua: 'Account',
      },
      settingOrganization: {
        en: 'Organization',
        ua: 'Організація',
      },
      settingRemove: {
        en: 'Remove',
        ua: 'Видалити',
      },
      review: {
        en: 'Review',
        ua: 'Перевірка',
      },
    },
  },
  error: {
    children: {
      isRequired: {
        en: 'This field is required',
        ua: "Поле обов'язковe для заповнення",
      },
      emailUsed: {
        en: 'Email is not registered',
        ua: 'Email не зареєстрований',
      },
      invalidEmail: {
        en: 'Email is not valid',
        ua: 'Пароль неправильний',
      },
      passwordSize: {
        en: 'Password should contain from 8 to 16 symbols',
        ua: 'Пароль повинен містити від 8 до 16 символів',
      },
      password: {
        en: 'Password is not correct',
        ua: 'Email не є допустимим',
      },
      matchPassword: {
        en: 'Passwords should be identical',
        ua: 'Паролі повинні співпадати',
      },
      finishBeforeStart: {
        en: 'Start time should be before finish time',
        ua: 'Час початку повинен бути раніше часу закінчення',
      },
      organizationNameSize: {
        en: 'Organization name should contain from 2 to 25 symbols',
        ua: 'Імʼя організації повинне містити від 2 до 25 символів',
      },
      organizationDescriptionSize: {
        en: 'Organization description should contain from 10 to 256 symbols',
        ua: 'Опис організації повинен містити від 10 до 256 символів',
      },
      phoneSize: {
        en: 'Phone number should follow patter +000-(00)-000-0000',
        ua: 'Номер телефону повинен відповідати шаблону +000-(00)-000-0000',
      }
    },
  },
  loading: {
    children: {
      loadAccount: {
        en: 'We are loading your profile...',
        ua: 'Ми завантажуємо ваш профіль...',
      },
    },
  },
  title: {
    children: {
      events: {
        en: 'Events',
        ua: 'Заходи',
      },
      newEvent: {
        en: 'New event',
        ua: 'Новий захід',
      },
      settingAccount: {
        en: "Account settings",
        ua: 'Налаштування акаунту'
      },
      settingOrganization: {
        en: "Organization settings",
        ua: 'Налаштування організації'
      },
      removeAccount: {
        en: 'Remove account',
        ua: 'Видалити акаунт'
      },
      generalInfo: {
        en: 'General info',
        ua: 'Загальна інформація',
      },
      mainInfo: {
        en: 'Main info',
        ua: 'Основна інформація',
      },
      address: {
        en: 'Place',
        ua: 'Місце проведення',
      },
      contacts: {
        en: 'Contacts',
        ua: 'Контакти',
      },
      time: {
        en: 'Time',
        ua: 'Час',
      },
      eventPhoto: {
        en: "Cover photo",
        ua: 'Обкладинка події',
      },
      additionalInfo: {
        en: 'Additional info',
        ua: 'Додаткова інформація',
      },
      seo: {
        en: 'SEO setting',
        ua: 'SEO налаштування',
      },
      tickets: {
        en: 'Price info',
        ua: 'Інформація про квитки',
      }

    },
  },
  btn: {
    children: {
      newEvent: {
        en: 'New event',
        ua: 'Новий захід',
      },
      save: {
        en: 'Save',
        ua: 'Зберегти',
      },
      create: {
        en: 'Create',
        ua: 'Створити',
      },
      login: {
        en: 'Log in',
        ua: 'Увійти',
      },
      reset: {
        en: 'Reset',
        ua: 'Відновити',
      },
      registration: {
        en: 'Sign up',
        ua: 'Зареєструватися',
      },
      finalRegistrationStep: {
        en: 'Go to the dashboard',
        ua: 'Перейти на головну сторінку',
      },
      sendToReview: {
        en: 'Send for review',
        ua: 'Відправити на модерацію',
      },
      onReview: {
        en: 'On review',
        ua: 'На модерації',
      },
      approve: {
        en: 'Approve ( Notification )',
        ua: 'Підтвердити (Оповіщення)',
      },
      sendApproveNotification: {
        en: 'Approve with  notification',
        ua: 'Approve with notification',
      },
      remove: {
        en: 'Remove',
        ua: 'Видалити',
      },
      reject: {
        en: 'Reject',
        ua: 'Відхилити',
      }
    },
  },
  eventType: {
    children: {
      meeting: {
        en: 'Event',
        ua: 'Подія',
      },
      exhibition: {
        en: 'Exhibition',
        ua: 'Виставка',
      },
      training: {
        en: 'Training',
        ua: 'Тренінг',
      },
      masterClass: {
        en: 'Master class',
        ua: 'Майстер клас',
      },
      course: {
        en: 'Course',
        ua: 'Курс',
      },
      lecture: {
        en: 'Lecture',
        ua: 'Лекція',
      },
      concert: {
        en: 'Concert',
        ua: 'Концерт',
      },
      boardGame: {
        en: 'Board Game',
        ua: 'Настільна гра',
      },
      sport: {
        en: 'Sport',
        ua: 'Спорт',
      },
      webinar: {
        en: 'Webinar',
        ua: 'Вебінар',
      },
      degustation: {
        en: 'Degustation',
        ua: 'Дегустація',
      },
      music: {
        en: 'Music',
        ua: 'Музика',
      },
      tour: {
        en: 'Tour',
        ua: 'Екскурсія',
      },
      online: {
        en: 'Online',
        ua: 'Онлайн',
      },
      standUp: {
        en: 'Stand Up',
        ua: 'Стендап',
      },
      theaters: {
        en: 'Theaters',
        ua: 'Театр',
      },
      networking: {
        en: 'Networking',
        ua: 'Networking',
      },
      film: {
        en: 'Film',
        ua: 'Фільм',
      },
      festival: {
        en: 'Festival',
        ua: 'Фестиваль ',
      },
      kids: {
        en: 'Kids',
        ua: 'Дітям',
      }
    },
  },
  accountType: {
    children: {
      standard: {
        en: 'Standard',
        ua: 'Стандартний',
      },
      company: {
        en: 'Company',
        ua: 'Компанія',
      },
      admin: {
        en: 'Admin',
        ua: 'Admin',
      },
    },
  },
  country: {
    children: {
      ua: {
        en: 'Ukraine',
        ua: 'Україна',
      },
    },
  },
  city: {
    children: {
      dnipro: {
        en: 'Dnipro',
        ua: 'Дніпро',
      },
      kiev: {
        en: 'Kyiv',
        ua: 'Київ',
      },
      lviv: {
        en: 'Lviv',
        ua: 'Львів',
      },
    },
  },
  price: {
    children: {
      free: {
        en: 'Free',
        ua: 'Безкоштовний',
      },
      paid: {
        en: 'Paid',
        ua: 'Платний',
      },
    },
  },
  organizationSetting: {
    children: {
      logoDescription: {
        en: 'Фото профілю організації',
        ua: 'Фото профілю організації',
      }
    }
  },
  setting: {
    children: {
      deleteDescription: {
        en: 'To remove your account, please send a request via next email ',
        ua: 'Щоб видалити ваш акаунт, будь ласка, зверніться з цим запитом за наступним email ',
      },
    },
  },
  breadcrumb: {
    children: {
      events: {
        en: 'Events',
        ua: 'Заходи'
      },
      dashboard: {
        en: 'Dashboard',
        ua: 'Головна'
      },
      removeAccount: {
        en: 'Remove account',
        ua: 'Видалити акаунт'
      },
      newEvent: {
        en: 'New event',
        ua: 'Створення нового заходу'
      },
      settingOrganization: {
        en: 'Organization settings',
        ua: 'Налаштування організації'
      },
      settingAccount: {
        en: 'Account settings',
        ua: 'Налаштування акаунту'
      }
    }
  },
  notification: {
    children: {
      largeFile: {
        en: 'File is too large. Its size should be less than 2MB.',
        ua: 'Файл завеликий. Розмір не має перевищувати 2MB.',
      },
      eventUpdated: {
        en: 'Your event was updated.',
        ua: 'Ваш захід оновлений',
      },
      eventPhotoUpdated: {
        en: 'Photo was updated',
        ua: 'Фото було оновлено',
      },
      sendToReview: {
        en: 'Your event was sent for review.',
        ua: 'Ваш захід відправлений на модерацію.',
      },
      eventApproved: {
        en: 'This event was approved.',
        ua: 'Цей захід підтверджений.',
      },
      eventRemoved: {
        en: 'Event was removed.',
        ua: 'Цей захід видалений.',
      },
      eventDuplicated: {
        en: 'Event was duplicated.',
        ua: 'Цей захід було продубльовано.',
      },
      finishRegistration: {
        en: 'Your registration is successful.',
        ua: 'Ваша реєстрація успішна.',
      },
      eventCreated: {
        en: 'Your event was created.',
        ua: 'Ваш захід створений.',
      },
      organizationUpdated: {
        en: 'Your organization data was created.',
        ua: 'Ваші дані були оновлені.',
      },
      eventReject: {
        en: 'This event was approved.',
        ua: 'Захід був відхилений',
      }
    }
  },
  infoFieldRequired: {
    en: '* These fields are required',
    ua: '* Поля обов’язкові для заповнення',
  },
  eventListEmpty: {
    en: "We didn't find any events on your request.",
    ua: 'Ми не знайши жодного заходу за вашим запитом.',
  },
  dashboard: {
    children: {
      welcomeTitle: {
        en: 'Welcome to Navcolo.',
        ua: 'Вітаємо в Navcolo.'
      },
      welcomeSubTitle: {
        en: 'Create your first event right now!',
        ua: 'Створіть свій перший захід прямо зараз!'
      },
      yourEvents: {
        en: 'Your events',
        ua: 'Ваші заходи'
      },
      addNewEvent: {
        en: "Create new event",
        ua: 'Створити новий захід',
      },
      reviewList: {
        en: 'Events on review',
        ua: 'Заходи на модерації'
      },
      counterAllEvent: {
        en: 'Events were created',
        ua: 'Події було створено'
      },
      counterOriginalEvent: {
        en: 'Original events were created',
        ua: 'Оригінальних  події було створено'
      },
      nextEvent: {
        en: 'Next event',
        ua: 'Наступна подія'
      },
      seeAllEvents: {
        en: 'See all events',
        ua: 'Переглянути усі'
      }
    }
  },
  eventStatus: {
    children: {
      draft: {
        en: 'Draft',
        ua: 'Чернетка'
      },
      onReview: {
        en: 'On review',
        ua: 'На модерації'
      },
      published: {
        en: 'Published',
        ua: 'Опубліковано'
      },
      rejected: {
        en: 'Rejected',
        ua: 'Відхилено'
      },
    }
  },
  support: {
    children: {
      askQuestion: {
        en: 'Ask question',
        ua: 'Задати питання'
      },
      part1: {
        en: 'If you have some questions, you can write to',
        ua: 'Якщо у вас є питання, ви можете написати на'
      },
    }
  },
  filter: {
    children: {
      eventFilter: {
        en: 'Filter',
        ua: 'Фільтр'
      },
      all: {
        en: 'All',
        ua: 'Усі'
      },
      future: {
        en: 'Future',
        ua: 'Майбутні'
      },
      onReview: {
        en: 'On review',
        ua: 'На модерації'
      },
      published: {
        en: 'Published',
        ua: 'Опубліковані'
      },
      draft: {
        en: 'Drafts',
        ua: 'Чернові'
      },
      reject: {
        en: 'Rejected',
        ua: 'Відхилені'
      },
      past: {
        en: 'Completed',
        ua: 'Завершені'
      }
    }
  },
  back: {
    en: 'Back',
    ua: 'Назад',
  },
  cancel: {
    en: 'Close',
    ua: 'Закрити',
  },
  send: {
    en: 'Send',
    ua: 'Відправити',
  },
  or: {
    en: 'or',
    ua: 'aбо',
  },
  email: {
    en: ' Email ',
    ua: ' Email ',
  },
  telegram: {
    en: ' Telegram ',
    ua: ' Telegram ',
  },
  chooseLanguage: {
    en: 'Choose language',
    ua: 'Оберіть мову',
  },
  duplicate: {
    en: 'Duplicate',
    ua: 'Дублювати',
  },
  remove: {
    en: 'Remove',
    ua: 'Видалити',
  },
  openEvent: {
    en: 'Open event',
    ua: 'Відкрити подію',
  },
  sendToEmail: {
    en: 'Sent to email',
    ua: 'Надіслано на електронну пошту',
  },
  dialog: {
    children: {
      approveTitle: {
        en: 'Sending for review',
        ua: 'Відправлення на модерацію',
      },
      approveDescription: {
        en: 'Great! You’ve created new event and it’s successfully saved. Now it is located in drafts. Do you want to send your event for review to make it posted as quickly as possible?',
        ua: 'Чудово! Ви створили новий захід і його успішно збережено. Наразі він знаходиться у чернетці. Відправити його на модерацію аби він якнайшвидше з’явився у додатку?',
      }
    }
  },
  tags: {
    children: {
      ua: {
        en: 'Stay with Ukraine',
        ua: 'Stay with Ukraine',
      },
      popular: {
        en: 'Popular',
        ua: 'Popular',
      },
      sales: {
        en: 'Sales',
        ua: 'Sales',
      },
      superPopular: {
        en: 'Super Popular',
        ua: 'Super Popular',
      },
    }
  },
  priorities: {
    children: {
      normal: {
        en: 'Normal',
        ua: 'Normal',
      },
      height: {
        en: 'Height',
        ua: 'Height',
      },
      super: {
        en: 'Super Height',
        ua: 'Super Height',
      },
    }
  },
  organizationEvent: {
    en: "Organization's event",
    ua: 'Подія організатора',
  },
  sendToTelegram: {
    en: "Send to Telegram",
    ua: 'Відправити у Telegram',
  }
};
