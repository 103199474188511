export const title = {
  fontWeight: 700,
  fontSize: '36px',
};

export const container = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
