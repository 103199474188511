const container = {
  marginTop: 16,
};

const organizationLabel = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 14,
  height: 30,
  fontWeight: 500,
  borderRadius: 4,
  color: '#fff',
  background: '#82b24d',
};

const ticketSection = {
  display: 'grid',
  gridRowGap: '16px',
  marginBottom: 20,
};

export default {
  container,
  organizationLabel,
  ticketSection,
};
