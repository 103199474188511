import { EVENT_STATUS, OUR_ORGANIZATIONS } from 'constants/common';

export const getEvents = ({ sortByOwner }) => (state) => {
  const search = state.events.search.toLowerCase();
  let events = Object.values(state.events.data);
  if (search) {
    events = events.filter((event) => event.name.toLowerCase().includes(search));
  }
  if (sortByOwner) {
    return events.sort((a) => (OUR_ORGANIZATIONS.includes(a.organization) ? 1 : -1));
  }
  return events.sort((a, b) => a.start - b.start);
};

export const getEventsCountOnReview = (state) =>
  Object.values(state.events.data || [])
    .filter((event) => event.status === EVENT_STATUS.ON_REVIEW)
    .sort((a, b) => a.start - b.start)
    .length;
export const getEventsCount = (state) => Object.values(state.events.data?.length || 0);
export const getEventsLoading = (state) => state.events.status;
export const getEventById = (eventId) => (state) => state.events.data[eventId];
export const getEventsFilter = (state) => state.events.filter;
export const getEventsSearch = (state) => state.events.search;
