import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';

import { storage } from './index';

export const getLink = (link, callback) => {
  getDownloadURL(ref(storage, link))
    .then(callback)
    .catch(() => callback(''));
};

export const uploadFile = ({
  file, filePath, fileName, callback,
}) => {
  const storageRef = ref(storage, `${filePath}/${fileName}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    'state_changed',
    () => {},
    () => {},
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then(() => {
        callback(fileName);
      });
    },
  );
};

export const deleteFile = (link) => {
  const desertRef = ref(storage, link);
  return deleteObject(desertRef);
};

export const removeFilesInFolder = async (link) => {
  const folderLink = ref(storage, link);
  listAll(folderLink)
    .then((res) => {
      const { items } = res;
      items.forEach((file) => deleteObject(file));
    });
};
