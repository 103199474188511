import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import dayjs from 'dayjs';
import { supportLanguages } from 'i18';
import { func } from 'prop-types';

import { ModalMenuIconBox } from 'UIComponent/ModalMenuIconBox';

import * as styles from './styles';

function IconLanguage({ handleClose }) {
  const { i18n, t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(i18n.language === 'en' ? 'gb' : i18n.language);

  useEffect(() => {
    dayjs.locale(selectedValue === 'ua' ? 'uk' : 'en');
  }, [selectedValue]);

  const handlerPopup = () => {
    setOpen((state) => !state);
  };

  const setValue = (item) => {
    setOpen(false);
    setSelectedValue(item.flag);
    dayjs.locale(item.flag === 'ua' ? 'uk' : 'en');
    i18n.changeLanguage(item.i18n);
    handleClose();
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={handlerPopup}>
        <ModalMenuIconBox>
          <Flag
            code={selectedValue}
            height={20}
            style={{ borderRadius: '4px' }}
          />
        </ModalMenuIconBox>
        <div style={{ paddingLeft: '10px' }}>{selectedValue === 'gb' ? 'English' : 'Українська'}</div>
      </Box>

      <Dialog onClose={handlerPopup} open={open}>
        <DialogTitle>{t('chooseLanguage')}</DialogTitle>
        <List sx={{ pt: 0 }}>
          {supportLanguages.map((item) => (
            <ListItem sx={{ cursor: 'pointer' }} onClick={() => setValue(item)} key={item.i18n}>
              <Flag style={styles.iconFlag} code={item.flag} />
              <div>{item.label}</div>
            </ListItem>
          ))}
        </List>
      </Dialog>
    </>
  );
}

IconLanguage.propTypes = {
  handleClose: func.isRequired,
};

export default IconLanguage;
