import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import { number, string } from 'prop-types';

import { OUR_ORGANIZATIONS } from 'constants/common';
import { EVENT_PAGE } from 'constants/locations';
import { getUrl } from 'helpers';
import { getLink } from 'services/firebase/storage';
import NavLink from 'UIComponent/Link';

import Setting from '../Setting';

import styles from './styles';

const statusStyle = {
  1: 'draft',
  2: 'onReview',
  3: 'publish',
  4: 'reject',
};

function EventItem(props) {
  const {
    id, logo, name, status, organizationId, city,
  } = props;
  const { t } = useTranslation();
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    if (logo) {
      const url = getUrl({ type: 'events', fileName: logo, id });
      if (logo.length < 45) {
        getLink(url, setLogoUrl);
      } else {
        setLogoUrl(logo);
      }
    }
  }, [logo]);

  return (
    <NavLink to={`${EVENT_PAGE}/${id}`}>
      <Paper sx={styles.containerHover} style={styles.container} elevation={3}>
        <div style={styles.photoContainer}>
          {logoUrl && <img src={logoUrl} alt="..." style={styles.logo} />}
        </div>
        <div style={styles.mainContainer}>
          <div style={styles.mainInfo}>
            <div style={styles.title}>{name}</div>
            <div style={{ ...styles.status, ...styles[statusStyle[status]] }}>{t(`eventStatus.${status}`)}</div>
            {!OUR_ORGANIZATIONS.includes(organizationId) && (<div style={styles.ownerEvent}>{t('organizationEvent')}</div>)}
          </div>
          <div style={{ height: 4 }} />
          <div style={styles.timeInfo}>
            <div style={styles.timeWrapper}>
              <div style={{ ...styles.timeItem, ...styles.timeItemText }}>{city}</div>
            </div>
          </div>
        </div>
        <Setting id={id} />
      </Paper>
    </NavLink>
  );
}

EventItem.propTypes = {
  id: string.isRequired,
  logo: string.isRequired,
  name: string.isRequired,
  status: number.isRequired,
};

export default EventItem;
