import { createSlice, current } from '@reduxjs/toolkit';

import { API_STATUS } from 'constants/common';

import {
  eventRemove,
  getAllEvents,
  getAllEventsOnReview,
  getEvent,
  updateEvent,
  updateEventStatus,
} from './actions';

const initialState = {
  status: API_STATUS.loading,
  data: [],
  page: 0,
  filter: null,
  search: '',
};

const eventMapper = (event) => ({
  ...event,
  status: event.event_status,
  start: event.start ? event.start : null,
  finish: event.finish ? event.finish : null,
  cost: event.cost ? `${event.cost}` : '0',
});

export const profileSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setEventsFilter: (state, { payload }) => ({
      ...state,
      filter: payload,
    }),
    setSearch: (state, { payload }) => ({
      ...state,
      search: payload,
    }),
    resetEvents: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllEvents.pending, (state) => ({
      ...current(state),
      status: API_STATUS.loading,
    }));

    builder.addCase(getAllEvents.rejected, (state) => ({
      ...state,
      status: API_STATUS.error,
    }));

    builder.addCase(getAllEvents.fulfilled, (state, { payload }) => {
      const { data } = payload;
      return {
        ...current(state),
        status: API_STATUS.success,
        data: data.events.reduce((acc, item) => ({
          ...acc,
          [item.id]: eventMapper(item),
        }), {}),
      };
    });

    builder.addCase(updateEvent.fulfilled, (state, { payload }) => {
      const { data } = payload;
      const event = data?.events[0] ?? {};
      return {
        ...current(state),
        status: API_STATUS.success,
        data: {
          [event.id]: eventMapper(event),
        },
      };
    });

    builder.addCase(updateEventStatus.fulfilled, (state, { payload }) => {
      const { data } = payload;
      const event = data?.events[0] ?? {};
      return {
        ...current(state),
        status: API_STATUS.success,
        data: {
          [event.id]: eventMapper(event),
        },
      };
    });

    builder.addCase(getEvent.fulfilled, (state, { payload }) => {
      const { data } = payload;
      return {
        ...current(state),
        status: API_STATUS.success,
        data: {
          [data.id]: eventMapper(data),
        },
      };
    });

    builder.addCase(getAllEventsOnReview.pending, (state) => ({
      ...current(state),
      status: API_STATUS.loading,
    }));

    builder.addCase(getAllEventsOnReview.fulfilled, (state, { payload }) => {
      const { data } = payload;
      return {
        ...current(state),
        status: API_STATUS.success,
        data: data.reduce((acc, item) => ({
          ...acc,
          [item.id]: eventMapper(item),
        }), {}),
      };
    });

    builder.addCase(getAllEventsOnReview.rejected, (state) => ({
      ...current(state),
      data: [],
      status: API_STATUS.error,
    }));

    builder.addCase(eventRemove.fulfilled, (state, { payload }) => {
      const { data: id } = payload;

      const currentState = current(state);
      const newData = { ...currentState.data };
      delete newData[id];
      return {
        ...currentState,
        data: newData,
      };
    });
  },
});

export const { setEventsFilter, setSearch, resetEvents } = profileSlice.actions;
export default profileSlice.reducer;
