import React from 'react';
import { useTranslation } from 'react-i18next';
import { object } from 'prop-types';

import CheckboxForm from 'components/formFields/CheckboxForm';
import NavigationLink from 'components/NavigationLink';
import { RESET_PASSWORD_PAGE } from 'constants/locations';

import * as styles from './styles';

function RememberMe(props) {
  const { control } = props;
  const { t } = useTranslation();

  return (
    <div style={styles.container}>
      <CheckboxForm
        id="remember"
        control={control}
      />

      <NavigationLink
        title={t('auth.forgotPassword')}
        link={RESET_PASSWORD_PAGE}
        style={styles.forgotPassword}
      />
    </div>
  );
}

RememberMe.propTypes = {
  control: object.isRequired,
};

export default RememberMe;
