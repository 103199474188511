export const formBuilderContainer = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '38px',
};

export const mainInfoContainer = {
  minWidth: '500px',
};
