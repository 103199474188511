export const mainContainer = {
  minHeight: '100vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

export const formContainer = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const formBlock = {
  width: '49%',
};

export const mainInfoContainer = {
  marginTop: '18px',
  width: '100%',
};

export const countryCityName = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridColumnGap: '10px',
};

export const info = {
  color: '#676767',
  fontSize: '14px',
  fontWeight: 300,
};
