import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const Button = styled(MuiButton)(({ textTransform, hidden }) =>
  ({
    minHeight: '56px',
    textTransform: textTransform || 'none',
    display: hidden ? 'none' : 'block',
  }));

export default Button;
