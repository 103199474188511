import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import 'dayjs/locale/uk';
import 'dayjs/locale/en';

import en from './assets/locales/en';
import ua from './assets/locales/ua';

export const supportLanguages = [
  { i18n: 'ua', label: 'Українська', flag: 'ua' },
  { i18n: 'en', label: 'English', flag: 'gb' },
];

const resources = {
  ua,
  en,
};

export default i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ua',
    fallbackLng: 'ua',
    debug: process.env.REACT_APP_ENV === 'development',
    detection: {
      order: ['localStorage', 'cookie'],
      caches: ['localStorage', 'cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  });
