import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { setSearch } from 'store/events';
import { getEventsSearch } from 'store/events/selectors';

function EventSearch() {
  const search = useSelector(getEventsSearch);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => () => {
    dispatch(setSearch(''));
  }, []);

  const handlerStatus = (e) => {
    dispatch(setSearch(e.target.value));
  };

  return (
    <Box sx={{ marginLeft: '10px' }}>
      <TextField
        value={search}
        onChange={handlerStatus}
        label={t('form.eventSearch')}
        placeholder={t('form.placeholder.eventSearch')}
        InputProps={{
          startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
        }}
      />
    </Box>
  );
}

export default EventSearch;
