export const getAccountSetting = (state) => ({
  ...state.profile.account,
  ...state.profile.socials,
});

export const getOrganization = (state) => state.profile.organization;
export const getOrganizationId = (state) => state.profile?.organization?.id;
export const getOrganizationName = (state) => state.profile?.organization?.name;
export const getEventCounter = (state) => state.profile?.organization?.eventCounter || 0;
export const getEventOriginalCounter = (state) => state.profile?.organization?.eventOriginalCounter || 0;

export const getIsAdmin = (state) => state.profile.account?.admin;
