import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import DialogMUI from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Dialog({
  title = '', text, submitText, cancelText, open = false, onClose, onSubmit,
}) {
  const { t } = useTranslation();
  return (
    <DialogMUI open={open} onClose={onClose}>
      <DialogTitle>
        {t(`dialog.${title}`)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(`dialog.${text}`)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t(cancelText)}</Button>
        <Button onClick={onSubmit}>{t(submitText)}</Button>
      </DialogActions>
    </DialogMUI>
  );
}

export default Dialog;
