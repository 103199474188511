const settingContainer = {
  display: 'flex',
  width: 48,
  justifyContent: 'center',
  alignItems: 'center',
};

export default {
  settingContainer,
};
