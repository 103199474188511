import React, { memo } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { func } from 'prop-types';

import logo from 'assets/svg/logoWithText.svg';
import { DASHBOARD_PAGE } from 'constants/locations';
import { AppBar as MyAppBar } from 'UIComponent/AppBar';
import Link from 'UIComponent/Link';

import User from './components/User';
import * as styles from './styles';

const MyToolbar = styled(Toolbar)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function AppBar({ handleDrawer }) {
  return (
    <MyAppBar position="fixed" color="secondary">
      <MyToolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => handleDrawer((state) => !state)}
          edge="start"
          style={styles.menuBtn}
        >
          <MenuIcon style={styles.menu} />
        </IconButton>

        <Typography
          variant="h6"
          component="div"
          sx={styles.name}
        >
          <Link to={DASHBOARD_PAGE} style={styles.link}>
            <img src={logo} alt="" style={styles.svg} />
          </Link>
        </Typography>

        <Box style={styles.btnContainer}>
          <User />
        </Box>
      </MyToolbar>
    </MyAppBar>
  );
}

AppBar.propTypes = {
  handleDrawer: func.isRequired,
};

export default memo(AppBar);
