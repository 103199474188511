import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { string } from 'prop-types';

import { DASHBOARD_PAGE } from 'constants/locations';
import { auth } from 'services/firebase';

function SetupRouter({ redirectPath = DASHBOARD_PAGE }) {
  const orgName = useSelector((state) =>
    state.profile?.organization?.name);
  const isLoggedIn = auth.currentUser;

  if (!orgName && isLoggedIn) {
    return <Outlet />;
  }

  return <Navigate replace to={redirectPath} />;
}

SetupRouter.propTypes = {
  redirectPath: string,
};

SetupRouter.defaultProps = {
  redirectPath: '',
};

export default SetupRouter;
