import { color } from '../../styles/text';

export const dividerContainer = {
  margin: '12px 0',
};

export const dividerText = {
  fontSize: '16px',
  color: color.divider,
  padding: '0 8px',
};
