import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { node, string } from 'prop-types';

import * as styles from './styles';

function DashboardCard(props) {
  const { title, children } = props;
  const { t } = useTranslation();
  return (
    <div>
      {title ? <Typography variant="h6">{title && t(`dashboard.${title}`)}</Typography> : <div style={{ height: 32 }} />}
      <Box sx={styles.container}>
        {children}
      </Box>
    </div>
  );
}

DashboardCard.propTypes = {
  children: node.isRequired,
  title: string,
};

DashboardCard.defaultProps = {
  title: '',
};

export default DashboardCard;
