import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import {
  bool,
  func,
  number,
  string,
} from 'prop-types';

import PageTitle from 'components/PageTitle';
import { EVENT_STATUS } from 'constants/common';
import { getIsAdmin } from 'store/profile/selectors';

import * as styles from './styles';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const BtnContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  button: {
    marginBottom: '1rem',
  },

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    button: {
      margin: '0 1rem',
    },
  },
}));

function HeaderPart(props) {
  const {
    onSave,
    status,
    title,
    isCreate,
    handelStatus,
  } = props;
  const isAdmin = useSelector(getIsAdmin);
  const { t } = useTranslation();
  const isShowApproveBtn = !isCreate && isAdmin && status === EVENT_STATUS.PUBLISH;

  return (
    <Container>
      <PageTitle title={title} useTranslate={isCreate} />
      <BtnContainer>
        {isShowApproveBtn && (
          <Button
            onClick={() => handelStatus(EVENT_STATUS.PUBLISH)}
            variant="contained"
            style={styles.btn}
          >
            {t('btn.sendApproveNotification')}
          </Button>
        )}
        <Button
          onClick={onSave}
          variant="contained"
          style={styles.btn}
        >
          {t('btn.save')}
        </Button>
        <Button
          onClick={() => handelStatus(EVENT_STATUS.REJECT)}
          variant="contained"
          color="error"
          style={styles.btn}
        >
          {t('btn.reject')}
        </Button>
      </BtnContainer>
    </Container>
  );
}

HeaderPart.propTypes = {
  onSave: func.isRequired,
  title: string,
  status: number,
  isCreate: bool,
  handelStatus: func,
};

HeaderPart.defaultProps = {
  title: '',
  status: null,
  isCreate: false,
  handelStatus: () => {
  },
};

export default HeaderPart;
