import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { bool, string } from 'prop-types';

import * as styles from './styles';

function Spinner({ title, isShow, fullScreen }) {
  const { t } = useTranslation();
  return (
    <Box style={fullScreen ? styles.fullContainer : styles.container} size={72} open={isShow}>
      <CircularProgress />
      {title && <div style={styles.title}>{t(`loading.${title}`)}</div>}
    </Box>
  );
}

Spinner.propTypes = {
  title: string,
  isShow: bool,
  fullScreen: bool,
};

Spinner.defaultProps = {
  title: '',
  isShow: false,
  fullScreen: false,
};

export default Spinner;
