import React from 'react';
import { useTranslation } from 'react-i18next';
import MuiDivider from '@mui/material/Divider';
import { func, string } from 'prop-types';

import * as styles from './styles';

function Divider(props) {
  const {
    title, textColor, cursor, onClick,
  } = props;
  const { t } = useTranslation();
  return (
    <div style={styles.dividerContainer}>
      <MuiDivider
        onClick={onClick}
        style={{
          ...styles.dividerText,
          ...(textColor && { color: textColor }),
          ...(cursor && { cursor }),
        }}
      >
        { t(title) }
      </MuiDivider>
    </div>
  );
}

Divider.propTypes = {
  title: string,
  textColor: string,
  cursor: string,
  onClick: func,
};

Divider.defaultProps = {
  title: '',
  textColor: '',
  cursor: '',
  onClick: null,
};

export default Divider;
