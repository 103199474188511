import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const AuthContainer = styled(Box)(
  () =>
    ({
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
);

export const Container = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'inherit',
  },
}));

export const ChildrenContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'inherit',
    minWidth: '550px',
  },
}));
