import React from 'react';

const text = `The Trust Technique Centre takes your privacy seriously. 
We are committed to data security and the fair and transparent processing of 
your personal data.  This Privacy Policy sets out how we will treat the personal
 data which you provide to us in compliance with applicable data protection law,
  in particular, the General Data Protection Regulation (EU) 2016/679 (GDPR).
           Please read this Policy carefully as it contains important
            information on who we are, how and why we collect, store, use and 
            share personal data, your rights in relation to your personal data 
            and how to contact us.`;

function PrivacyPolicy() {
  return (
    <>
      <h2>Internet Privacy & Cookies Policy</h2>
      <div>
        {text}
      </div>
    </>
  );
}

export default PrivacyPolicy;
