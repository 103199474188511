import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

const openedMixin = (theme) =>
  ({
    width: theme.config.drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

const closedMixin = (theme) =>
  ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
    [theme.breakpoints.down('md')]: {
      width: 0,
    },
  });

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) =>
    prop !== 'open',
})(
  ({ theme, open }) =>
    ({
      width: theme.config.drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
      [theme.breakpoints.down('md')]: {
        width: 0,
      },
    }),
);

export default Drawer;
