import { color } from 'styles/text';

export const menuBtn = {
  marginRight: 8,
};

export const name = {
  flexGrow: 1,
  // display: { xs: 'none', sm: 'block' },
};

export const btnContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const appBtn = {
  color: '#fff',
};

export const userContainer = {
  display: 'flex',
  alignItems: 'center',
  marginLeft: '12px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#E0E0E0',
  },
};

export const userAvatar = {
  width: 34,
  height: 34,
};

export const userName = {
  display: { xs: 'none', sm: 'block' },
  fontWeight: 600,
  margin: '0 8px 0 4px',
  color: color.black,
};

export const link = {
  display: 'flex',
};

export const svg = {
  height: '32px',
};

export const menu = {
  color: color.black,
};
