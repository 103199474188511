import React from 'react';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { Stack } from '@mui/material';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { SETTING_ACCOUNT_PAGE } from 'constants/locations';
import { ModalMenuIconBox } from 'UIComponent/ModalMenuIconBox';

import IconLanguage from '../IconLanguage';

import * as styles from './styles';

function SettingButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <KeyboardArrowDownRoundedIcon style={styles.menu} />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={{
          '.MuiList-root': {
            minWidth: '270px',
          },
        }}
      >
        <Stack>
          <MenuItem onClick={() => {
            navigate(SETTING_ACCOUNT_PAGE);
            handleClose();
          }}
          >
            <ModalMenuIconBox>
              <SettingsRoundedIcon />
            </ModalMenuIconBox>
            <Typography sx={{ paddingLeft: '10px' }}>
              Setting
            </Typography>
          </MenuItem>
          <MenuItem>
            <IconLanguage handleClose={handleClose} />
          </MenuItem>
        </Stack>

      </Menu>
    </>
  );
}

export default SettingButton;
