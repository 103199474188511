import { basicHeader, getAuth, request } from '../configs';

export const getEvent = async (id) =>
  request.get(
    `api/event/${id}`,
    {
      headers: {
        ...getAuth(),
        ...basicHeader(),
      },
    },
  ).then((res) => res.data);

export const updateEvent = async (body) =>
  request.post(
    'api/event/update',
    JSON.stringify(body),
    {
      headers: {
        ...getAuth(),
        ...basicHeader(),
      },
    },
  ).then((res) => res.data);

export const updateEventStatus = async (values) =>
  request.post(
    '/api/event/status',
    values,
    {
      headers: {
        ...getAuth(),
        ...basicHeader(),
      },
    },
  ).then((res) => res.data);

export const removeEvent = async (id) =>
  request.delete(
    '/api/event/remove',
    {
      headers: {
        ...getAuth(),
        ...basicHeader(),
      },
      data: { id },
    },
  ).then((res) => res.data);

export const getAllEvents = async ({ id, filter }) =>
  request.post(
    '/api/event/organization/list',
    {
      organizationId: id,
      ...(filter && { filter }),
    },
    {
      headers: {
        ...getAuth(),
        ...basicHeader(),
      },
    },
  ).then((res) => res.data);

export const getAllEventsOnReview = async () =>
  request.get(
    '/api/event/all/onReview',
    {
      headers: {
        ...getAuth(),
        ...basicHeader(),
      },
    },
  ).then((res) => res.data);

export const toTelegram = async ({ eventId }) => request.post(
  '/api/telegram/toTopic',
  { eventId },
  {
    headers: {
      ...getAuth(),
      ...basicHeader(),
    },
  },
).then((res) => res.data);
