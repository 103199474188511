import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { bool } from 'prop-types';

import Spinner from 'components/Spinner';
import { API_STATUS } from 'constants/common';
import { getAllEvents } from 'store/events/actions';
import {
  getEvents, getEventsFilter, getEventsLoading,
} from 'store/events/selectors';
import { getOrganizationId } from 'store/profile/selectors';

import EventItem from './components/EventItem';

function EventTable(props) {
  const { disableRequest, hideEmptyText } = props;
  const events = useSelector(getEvents({ sortByOwner: true }));
  const status = useSelector(getEventsLoading);
  const organizationId = useSelector(getOrganizationId);
  const filter = useSelector(getEventsFilter);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!disableRequest && filter) {
      dispatch(getAllEvents({
        id: organizationId,
        ...(filter && { filter }),
      }));
    }
  }, [filter, dispatch, disableRequest]);

  useEffect(() => {
    setPage(1);
  }, [filter]);

  if (status === API_STATUS.loading && !events.length) {
    return <Spinner isShow />;
  }

  const renderEvent = [...events].slice(0, 20 * page);

  return (
    <div>
      {renderEvent.map((event) => (
        <EventItem
          key={event.id}
          id={event.id}
          logo={event.logo}
          name={event.name}
          status={event.status}
          city={event.city}
          organizationId={event.organization}
        />
      ))}
      <div style={{
        display: 'flex',
        justifyContent: 'center',
      }}
      >
        {renderEvent.length !== events.length && (
          <Button
            variant="contained"
            onClick={() => {
              setPage((prevState) => prevState + 1);
            }}
          >
            LOAD MORE
          </Button>
        )}
      </div>

      {!hideEmptyText && events.length === 0 && (
        <div style={{
          height: 60,
          width: '100%',
          padding: '1rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <div>{t('eventListEmpty')}</div>
        </div>
      )}
    </div>
  );
}

EventTable.propTypes = {
  disableRequest: bool,
  hideEmptyText: bool,
};

EventTable.defaultProps = {
  disableRequest: false,
  hideEmptyText: false,
};

export default EventTable;
