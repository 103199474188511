import { color } from '../../styles/text';

export const container = {
  display: 'flex',
  justifyContent: 'center',
};

export const question = {
  color: color.lightGray,
  marginRight: '10px',
};

export const mainText = {
  color: color.black,
};
