import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {
  array,
  bool,
  func,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';

function SelectField(props) {
  const { t } = useTranslation();
  const {
    error, options, value, label, type, onChange, placeholder, disabled, multiple,
  } = props;
  const { message } = error;

  const err = message ? t(`error.${message}`) : '';

  return (
    <FormControl fullWidth error={!!err}>
      <InputLabel id={type}>{label}</InputLabel>
      <Select
        multiple={multiple}
        labelId={type}
        value={value}
        label={label}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      >
        {options.map(({ title, value: v }) =>
          (
            <MenuItem key={v} value={v}>{ t(title) }</MenuItem>
          ))}
      </Select>
      {err && <FormHelperText>{err}</FormHelperText>}
    </FormControl>
  );
}

SelectField.propTypes = {
  error: object,
  options: array,
  value: oneOfType([string, number, bool]),
  placeholder: string,
  label: string.isRequired,
  type: string,
  disabled: bool,
  multiple: bool,
  onChange: func.isRequired,
};

SelectField.defaultProps = {
  error: {},
  options: [],
  value: '',
  placeholder: '',
  type: '',
  disabled: false,
  multiple: false,
};

export default SelectField;
