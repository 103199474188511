import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Container from '@mui/material/Container';

import Breadcrumbs from 'components/Breadcrumbs';
import Dialog from 'components/Dialog';
import EventComponent from 'components/Event';
import EventHeader from 'components/EventHeader';
import Spinner from 'components/Spinner';
import { EVENT_STATUS } from 'constants/common';
import { EVENTS_PAGE } from 'constants/locations';
import { eventMapperToServer } from 'helpers/event';
import { deleteFile, removeFilesInFolder } from 'services/firebase/storage';
import {
  eventRemove, getEvent, sendEventToTelegram, updateEvent, updateEventStatus,
} from 'store/events/actions';
import { getEventById } from 'store/events/selectors';

const breadcrumbs = [
  {
    title: 'events',
    link: EVENTS_PAGE,
  },
];

function Event() {
  const { eventId } = useParams();
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const submitRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const event = useSelector(getEventById(eventId));

  useEffect(() => {
    dispatch(getEvent(eventId));
  }, [eventId]);

  const onSubmit = (body) => {
    dispatch(updateEvent(eventMapperToServer({
      eventId,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...body,
    })))
      .then(() => {
        toast.success(t('notification.eventUpdated'));
        // setShowPopup(true);
      });
  };

  const savePhoto = (body) => {
    dispatch(updateEvent(eventMapperToServer({ eventId, ...body })))
      .then(() => {
        toast.success(t('notification.eventPhotoUpdated'));
      });
  };

  const submit = () => {
    submitRef.current?.click();
  };

  const handelStatus = (status, callBack = () => {
  }) => {
    console.log({ id: event.id, status });
    dispatch(updateEventStatus({ id: event.id, status }))
      .then(() => {
        if (status === EVENT_STATUS.PUBLISH) {
          toast.success(t('notification.eventApproved'));
        }
        if (status === EVENT_STATUS.REJECT) {
          toast.success(t('notification.eventReject'));
        }
        if (status === EVENT_STATUS.ON_REVIEW) {
          toast.success(t('notification.sendToReview'));
          callBack();
        }
      });
  };

  const removeEvent = () => {
    dispatch(eventRemove(event.id))
      .then(() => {
        const isNewApi = event.logo.length > 9;

        if (isNewApi) {
          const folderUrl = `event/${event.id}`;
          removeFilesInFolder(folderUrl);
        } else {
          const url = `events/${event.logo}`;
          deleteFile(url);
        }
      })
      .then(() => navigate(-1))
      .then(() => {
        toast.success(t('notification.eventRemoved'));
      });
  };

  const sendToTelegram = () => {
    dispatch(sendEventToTelegram({ eventId: event.id }));
  };

  const closePopup = () => setShowPopup(false);
  const onSubmitPopup = () => handelStatus(EVENT_STATUS.ON_REVIEW, closePopup);

  if (!event?.id) {
    return <Spinner />;
  }

  return (
    <>
      <Breadcrumbs current={event.name} breadcrumbs={breadcrumbs} useTranslate={false} />
      <EventHeader
        isShowSave
        title={event.name}
        onSave={submit}
        handelStatus={handelStatus}
        status={event.status}
      />
      <Container>
        <EventComponent
          onSubmit={onSubmit}
          savePhoto={savePhoto}
          submitRef={submitRef}
          values={event}
          removeEvent={removeEvent}
          organizationId={event.organization}
          sendToTelegram={sendToTelegram}
        />
      </Container>

      <Dialog
        open={showPopup}
        onClose={closePopup}
        onSubmit={onSubmitPopup}
        title="approveTitle"
        text="approveDescription"
        cancelText="cancel"
        submitText="send"
      />
    </>
  );
}

export default Event;
