export const headerContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const btnContainer = {
  display: 'grid',
  gridGap: '1rem',
};

export const btn = {
  height: '44px',
  paddingLeft: '24px',
  paddingRight: '24px',
  gridRow: 1,
};
