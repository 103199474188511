import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Container from '@mui/material/Container';

import EventsList from 'components/EventsList';
import { resetEvents } from 'store/events';
import { getAllEventsOnReview } from 'store/events/actions';

function ReviewEvents() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllEventsOnReview());
    return () => {
      dispatch(resetEvents());
    };
  }, []);

  return (
    <Container>
      <EventsList disableRequest />
    </Container>
  );
}

export default ReviewEvents;
