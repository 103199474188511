import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const alertPopup = 'alertPopup';

function AlertPopup() {
  const [isShowAlertModal, setIsShowAlertModal] = useState(false);
  const [cookies, setCookie] = useCookies([alertPopup]);

  useEffect(() => {
    if (!cookies[alertPopup]) {
      setIsShowAlertModal(true);
    }
  }, []);

  const hideCloseAlert = () => {
    setCookie(alertPopup, true, {
      path: '/',
      maxAge: 60 * 60, // 60 minutes in seconds
    });
    setIsShowAlertModal(false);
  };

  const handlerTelegram = () => {
    window.open('https://t.me/navcolo_support', '_blank');
  };

  return (
    <Dialog
      open={isShowAlertModal}
      onClose={hideCloseAlert}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        🚨Важливе повідомлення 🚨
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Повідомляємо, що ми припинили випускати оновлення для нашого сайту.
          Наразі ви можете створювати події через мобільний застосунок,
          де є більш широкий функціонал. Будь ласка, увійдіть у ваш акаунт через мобільн
          ий застосунок. Якщо ви маєте питання - напишіть нам у підтримку
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={hideCloseAlert}>Закрити</Button>
        <Button onClick={handlerTelegram}>
          Написати у telegram
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertPopup;
