import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';

import SettingHeader from 'components/SettingHeader';

import * as styles from './styles';

function SettingRemove() {
  const name = useSelector((state) => state.profile.organization.name);

  return (
    <Container>
      <SettingHeader
        currentPage="removeAccount"
        title="removeAccount"
        hideButton={false}
      />

      <Container style={styles.infoContainer}>
        <div>
          {`Ваш акаунт є власником у наступній організації: ${name}`}
        </div>
        <div style={styles.description}>
          <Trans>setting.deleteDescription</Trans>
          <a href="mailto: navcolo.space@gmail.com"><strong>navcolo.space@gmail.com</strong></a>
          .
        </div>
      </Container>
    </Container>
  );
}

export default SettingRemove;
