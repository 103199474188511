import axios from 'axios';

import { auth } from 'services/firebase';

export const getAuth = () => {
  const { currentUser } = auth;
  const { accessToken } = currentUser;

  return {
    Authorization: accessToken,
  };
};

export const basicHeader = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
});

export const request = axios.create({
  baseURL: process.env.REACT_APP_ENV === 'development'
    ? process.env.REACT_APP_SERVER_LOCAL_DEV
    : process.env.REACT_APP_SERVER_LINK,
});
