import { node } from 'prop-types';

function SetupLayout(props) {
  const { children } = props;
  return children;
}

SetupLayout.propTypes = {
  children: node.isRequired,
};

export default SetupLayout;
