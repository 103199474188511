import MuiListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';

const DrawerListItemText = styled(MuiListItemText)(({ theme, open: isOpen }) =>
  ({
    opacity: isOpen ? 1 : 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }));

export default DrawerListItemText;
