import React from 'react';
import Container from '@mui/material/Container';

import logo from 'assets/svg/logoWithText.svg';
import * as styles from 'components/AppBar/styles';
import AuthLink from 'components/AuthLink';
import AuthTitle from 'components/AuthTitle';
import Divider from 'components/Divider';
import LoginWithGoogle from 'components/LoginWithGoogle';
import { REGISTRATION_PAGE } from 'constants/locations';
import { authFormContainer, gridContainer } from 'styles/container';

import Form from './components/Form';

function Login() {
  return (
    <Container style={authFormContainer}>
      <img src={logo} alt="" style={styles.svg} />
      <AuthTitle title="loginTitle" subTitle="loginSubTitle" />
      <LoginWithGoogle />
      <Divider title="or" />
      <div style={gridContainer}>
        <Form />
        <AuthLink
          link={REGISTRATION_PAGE}
          question="dontHaveAccount"
          text="registration"
        />
      </div>
    </Container>
  );
}

export default Login;
