import { isConfirmPassword, isEmail, isPassword } from './validations';

export const validationEmail = {
  email: isEmail,
};

export const validationPassword = {
  password: isPassword,
};

export const validationConfirmPassword = {
  password: isPassword,
  confirmPassword: isConfirmPassword,
};
