import * as yup from 'yup';

export const isEmail = yup.string()
  .email('invalidEmail')
  .required('isRequired');

export const isPassword = yup.string()
  .min(8, 'passwordSize')
  .max(16, 'passwordSize')
  .required('isRequired');

export const isConfirmPassword = yup.string()
  .oneOf([yup.ref('password')], 'matchPassword');

export const isOrganizationName = yup.string()
  .min(2, 'organizationNameSize')
  .max(32, 'organizationNameSize')
  .required('isRequired');

export const isOrganizationDescription = yup.string()
  .min(10, 'organizationDescriptionSize')
  .max(512, 'organizationDescriptionSize');

export const isPhone = yup.string()
  .min(18, 'phoneSize')
  .max(18, 'phoneSize');

export const organizationValidation = {
  name: isOrganizationName,
  description: isOrganizationDescription,
};
