export const headerContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const btnAdd = {
  height: '44px',
  paddingLeft: '24px',
  paddingRight: '24px',
};

export const filter = {
  width: '200px',
};

export const optionContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '16px 0',
};

export const row = {
  '&:last-child td, &:last-child th': { border: 0 },
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#E0E0E0',
  },
};

export const emptyContainer = {
  height: '100px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#000000',
  opacity: 0.5,
};
