import MuiBox from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const FormBox = styled(MuiBox)(({ styles }) =>
  ({
    display: 'grid',
    gridRowGap: '16px',
    ...styles,
  }));

export default FormBox;
