import { width } from 'styles/text';

export const container = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '42px',
};

export const forgotPassword = {
  fontWeight: width.bold,
};
