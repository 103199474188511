import React from 'react';
import MuTextField from '@mui/material/TextField';
import {
  bool,
  func,
  number,
  object,
  oneOf,
  string,
} from 'prop-types';

function TextField(props) {
  const {
    error,
    value,
    onChange,
    label,
    type,
    placeholder,
    multiline,
    minRows,
    onClick,
    disabled,
    required,
    fullWidth,
    inputProps,
  } = props;

  return (
    <MuTextField
      variant="outlined"
      autoComplete="off"
      required={required}
      error={!!error}
      helperText={error}
      value={value}
      onChange={onChange}
      onClick={onClick}
      label={label}
      type={type}
      multiline={multiline}
      placeholder={placeholder}
      minRows={minRows}
      disabled={disabled}
      fullWidth={fullWidth}
      inputProps={inputProps}
    />
  );
}

TextField.propTypes = {
  error: string,
  value: string,
  label: string,
  placeholder: string,
  multiline: bool,
  disabled: bool,
  required: bool,
  fullWidth: bool,
  minRows: number,
  onChange: func,
  onClick: func,
  inputProps: object,
  type: oneOf(['text', 'password']),
};

TextField.defaultProps = {
  error: {
    message: '',
  },
  value: '',
  label: '',
  placeholder: '',
  type: 'text',
  multiline: false,
  disabled: false,
  required: false,
  fullWidth: false,
  minRows: 3,
  onChange: null,
  onClick: () => {},
  inputProps: {},
};

export default TextField;
