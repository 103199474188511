const container = {
  width: '100%',
  display: 'flex',
  overflow: 'hidden',
  marginBottom: 16,
};

const containerHover = {
  transition: 'transform 0.5s linear',

  '&:hover': {
    cursor: 'pointer',
    transform: 'scale(1.01)',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  },
};

const photoContainer = {
  width: 164,
};

const logo = {
  objectFit: 'cover',
  width: '100%',
};

const mainContainer = {
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'column',
  marginLeft: 24,
  padding: '12px 0',
};

const settingContainer = {
  display: 'flex',
  width: 44,
  justifyContent: 'center',
  alignItems: 'center',
};

const mainInfo = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  columnGap: 12,
  rowGap: 6,
};

const title = {
  fontWeight: 500,
  fontSize: 16,
  color: '#000',
  textTransform: 'capitalize',
};

const status = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 12,
  minHeight: 20,
  fontWeight: 500,
  padding: '4px 8px',
  borderRadius: 4,
  color: '#fff',
};

const ownerEvent = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 12,
  minHeight: 20,
  fontWeight: 500,
  padding: '4px 8px',
  borderRadius: 4,
  color: '#fff',
  background: '#82b24d',
};

const draft = {
  background: '#969696',
};

const onReview = {
  background: '#FFC107',
};

const publish = {
  background: '#82b24d',
};

const reject = {
  background: '#ce3c55',
};

const timeInfo = {
  display: 'flex',
  alignItems: 'center',
  flexFlow: 'wrap',
};

const timeWrapper = {
  display: 'flex',
};

const timeItem = {
  marginRight: 4,
  color: 'rgba(0, 79, 153, 0.4)',
  fontWeight: 500,
};

const timeItemText = {
  color: '#004F99',
  fontSize: 16,
  textTransform: 'capitalize',
};

export default {
  container,
  containerHover,
  photoContainer,
  logo,
  mainContainer,
  settingContainer,
  mainInfo,
  timeInfo,
  timeItem,
  timeItemText,
  title,
  status,
  draft,
  reject,
  onReview,
  publish,
  ownerEvent,
  timeWrapper,
};
