import { basicHeader, getAuth, request } from '../configs';

export const getProfile = async () => {
  const { data } = await request.get('/api/user', {
    headers: {
      ...getAuth(),
      ...basicHeader(),
    },
  });
  return data;
};

export const updateOrganization = async (body) =>
  request.patch(
    '/api/organization',
    JSON.stringify(body),
    {
      headers: {
        ...getAuth(),
        ...basicHeader(),
      },
    },
  ).then((res) =>
    res.data);
