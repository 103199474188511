export const container = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  aspectRatio: '1 / 1',
  border: '1px dashed #969696',
  borderRadius: '8px',
  transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
  color: '#969696',
  padding: '1rem',

  '&:hover': {
    cursor: 'pointer',
    transform: 'scale(1.01)',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  },
};
