import React, { useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { node } from 'prop-types';

import AlertPopup from 'components/AlertPopup';
import AppBar from 'components/AppBar';
import Drawer from 'components/Drawer';
import BackDrop from 'UIComponent/BackDrop';
import DrawerHeader from 'UIComponent/DrawerHeader';

import * as styles from './styles';

function ContentLayout(props) {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Box sx={styles.containerLayout}>
        <CssBaseline />
        <AppBar handleDrawer={setIsOpen} />
        <Drawer isOpen={isOpen} setIsOpen={setIsOpen} />
        <Box component="main" sx={styles.mainContent}>
          <DrawerHeader />
          {children}
          <BackDrop open={isOpen} onClick={() => setIsOpen(!isOpen)} />
        </Box>
      </Box>

      <AlertPopup />
    </>
  );
}

ContentLayout.propTypes = {
  children: node.isRequired,
};

export default ContentLayout;
