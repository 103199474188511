import { Link as NavLink } from 'react-router-dom';
import styled from '@emotion/styled';

import { color } from 'styles/text';

const Link = styled(NavLink)`
  color: ${color.black};
  text-decoration: none;

  :hover {
    cursor: pointer;
  }
  
  ${(props) =>
    props.isUnderline && {
      textDecoration: 'underline',
    }};
  
  ${(props) =>
    props.disabled && {
      color: color.black,
      cursor: 'default',
    }};
`;

export default Link;
